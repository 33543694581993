import React, { FC } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import { useGSAP } from '@gsap/react';
import gsap from 'gsap'


export interface MainNav1Props {
  className?: string;
}


const handleLogout = () => {
  sessionStorage.clear()
  sessionStorage.clear()

  window.location.href = "/login"

}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {

  useGSAP(()=>{
    var tl = gsap.timeline();

    tl.from(".logo",{
      y:"-20",
      opacity:0,
      delay:-.5,
      duration:1.5,
      ease: "Expo.easeInOut"
    })
    .from(".side-nav",{
      y:"-20",
      opacity:0,
      delay:-.5,
      duration:1.5,
      ease: "Expo.easeInOut"
    })
    .to(".brandname",{
      y:"0",
      delay:-0.5,
      duration:1.5,
      stagger:.2,
      ease: "power4.out"
    })
    .from(".model-container-3d",{
      opacity:0,
      duration:1,
      delay:-.8,
    })
    .from(".more",{
      y:"20",
      opacity:0,
      duration:1,
      delay:-.5,
      ease: "Expo.easeInOut"
    })
  });

  const isLoggedIn = sessionStorage.getItem("loggedIn")
  return (
    <div className={`nc-MainNav1 relative z-10 ${className}`}>
      <div className="flex px-4 side-nav lg:container py-4 lg:py-5 relative flex justify-between items-center">
        <div className="hidden logo flex  md:flex justify-start flex-1 items-center space-x-4 sm:space-x-10">
          <Logo />
          <Navigation />
        </div>

        <div className="hidden flex lg:flex items-center flex-shrink-0 space-x-4">

          {/* <button
            onClick={() => window.location.href = "/lobby"}
            className="relative bg-gradient-to-r from-pink-400 to-red-500 hover:from-pink-500 hover:to-orange-500 text-black px-4 py-2 rounded-full text-white disabled:cursor-not-allowed"
          >
            PlayGround
          </button> */}

           <button
            onClick={() => window.location.href = "/blog"}
            className="relative bg-gradient-to-r from-pink-400 to-red-500 hover:from-pink-500 hover:to-orange-500 text-black px-4 py-2 rounded-full text-white disabled:cursor-not-allowed"
          >
            Blogs
          </button> 


          <button
            onClick={() => window.location.href = "/subscription"}
            className="text-black px-4 py-2 rounded-full text-white animate-gradient"
          >
            Subscribe
          </button>



          <div className=" xl:flex items-center space-x-0.5">
            <div className="px-1" />
            {isLoggedIn ? (
              <div className="flex items-center space-x-4">
  <ButtonPrimary onClick={handleLogout} className="bg-red-600 hover:bg-red-800">
    Logout
                </ButtonPrimary>

  <button
  onClick={() => window.location.href = "/profile"}
  className="relative group flex flex-col items-center"
>
  <img
    src="/profileAvatar.svg"
    alt="Profile Avatar"
    className="w-20 h-20 rounded-full transition-transform duration-300 ease-in-out transform group-hover:scale-105"
  />
</button>

</div>

            ) : (
              <ButtonPrimary href="/login">Login</ButtonPrimary>
            )}
          </div>
        </div>

        {/* Mobile Menu Bar and Dark Mode Switch */}
        {/* <MenuBar /> */}
        {/* <SwitchDarkMode /> */}
      </div>
    </div>
  );
};

export default MainNav1;
