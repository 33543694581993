import React, { FC, Fragment, useState, useEffect } from "react";
import Badge from "shared/Badge/Badge";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandcuffs, faWineBottle, faHeartBroken, faSadCry, faFaceGrinTongueWink, faBoxesStacked, faUserDoctor, faPerson, faHandsAslInterpreting } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import { apiUri } from "../../constants";
import Alert from "shared/Alert/Alert";
import PeerPopover from "./peerPopover";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import LoadingIcon from "components/Loading/LoadingIcon";
import CreateLobbyDirect from "containers/HostMootcourt/CreateLobbyForTeam";
import JoinLobbyPopover from "containers/HostMootcourt/JoinLobbyPopover";
import TeamSelectPopover from "containers/HostMootcourt/TeamSelectPopover";
import SideSelectPopover from "containers/PageCourt/SideSelectPopover";
import IdealWayToGoPopover from "./IdealWayToGoPopover";
import DescriptionRenderer from "./DescriptionRenderer";

interface caseDetailsProp {
  id: string;
  caseTitle: string;
  type: string;
  description: string;
  caseLaws: string[];
  modelFeed: string;
  attributes: string[];
  oneLineQues: string;
  aim: string;
}


const CaseDetails: FC<caseDetailsProp> = ({ id, caseTitle, type, description, caseLaws, attributes, oneLineQues, aim }) => {
  const thisPathname = useLocation().pathname;
  const router = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [friendPopover, setFriendPopover] = useState(false)

  const [freeTrialRemaining, setIsTrialRemaining] = useState(false)
  const [makeLobbyDirect, setMakeLobbyDirect] = useState(false)
  const [isMakeLobbyOpen, setisMakeLobbyOpen] = useState(false);
  const [isJoinLobbyOpen, setisJoinLobbyOpen] = useState(false);
  const [openTeamSelect, setOpenTeamSelect] = useState(false);
  const [enteredLobby, setEnteredLobby] = useState('');

  const [loading, setLoading] = useState(true);
  const subscribed = sessionStorage.getItem("subscribed")
  const tempFree = sessionStorage.getItem('freeCases') ? Number(sessionStorage.getItem('freeCases')) : 0;

  const [freeCases, setFreeCases] = useState(tempFree)

  const [idealWayToGo, setIdealWayToGo] = useState(true)

  const [lobbyName, setLobbyName] = useState("")
  const [allCourtsOpen, setAllCourtsOpen] = useState(false)
  const [allLobbiesOpen, setAllLobbiesOpen] = useState(false)
  const [showSideSelectPopover, setShowSideSelectPopover] = useState(false)

  const handleJoinLobbyClose = () => {
    // //console.log("Handle join lobby close");
    setMakeLobbyDirect(false);
    setisMakeLobbyOpen(false);
    setisJoinLobbyOpen(false);
    setLobbyName("")
  };

  const handleJoinLobbyOpen = () => {
    setisJoinLobbyOpen(true);
  };

  const handleIdealWayPopoverClose = () => {
    setIdealWayToGo(false)
  }

  const handleSideSelectClose = () => {
    setShowSideSelectPopover(false)
  }

  const handleJoinLobby = (name: string) => {
    setLobbyName(name)
    handleJoinLobbyOpen();
  };

  const handleTeamSelectClose = () => {
    setOpenTeamSelect(false);
  };

  const handleCreateLobby = async () => {
    setisMakeLobbyOpen(true);
    //console.log("Creating new lobby");
  };

  const handleViewAllLobbies = () => {
    setAllLobbiesOpen(true)
  };

  const freeTrial = async () => {
    const email = sessionStorage.getItem("email")

    if (email) {
      try {
        const response = await axios.post(`${apiUri}/getFreeTrial`, { email: email });
        if (response.data.data.freeCases > 0) {
          setIsTrialRemaining(true)
            setFreeCases(response.data.data.freeCases)
          sessionStorage.setItem("freeCases", response.data.data.freeCases)
          await updateFreeTrail()
        } else {
          sessionStorage.setItem("freeCases", "0")
        }
      } catch (error) {
        console.error('Error fetching case details:', error);
      }
    }
  }

  const updateFreeTrail = async () => {
    const email = sessionStorage.getItem("email")

    if (email) {
      try {
        const response = await axios.post(`${apiUri}/updateTrial`, { email: email });
        //console.log(response)
      } catch (error) {
        console.error('Error fetching case details:', error);
      }
    }
  }

  const handlePopoverClose = () => {
    setFriendPopover(false)
  }

  const handleTeamFight = async () => {

    if (sessionStorage.getItem("subscribed") === "true") {

       sessionStorage.setItem("caseId", id)

        setMakeLobbyDirect(true)
    }
    else if (sessionStorage.getItem("subscribed") === "false") {
      await freeTrial()
      if (sessionStorage.getItem("loggedIn") && sessionStorage.getItem("freeCases") != "0") {

        sessionStorage.setItem("caseId", id)

        setMakeLobbyDirect(true)

      } else if (sessionStorage.getItem("freeCases") == "0") {
        setShowAlert(true)
      }
    }
    else {
      window.location.href = "/login"
    }
  }

  const handleFightNow = async () => {

    if (sessionStorage.getItem("subscribed") === "true") {
       setShowSideSelectPopover(true)
    } else if (sessionStorage.getItem("subscribed") === "false") {
      await freeTrial()
      if (sessionStorage.getItem("loggedIn") && sessionStorage.getItem("freeCases") != "0") {

        setShowSideSelectPopover(true)
      } else if (sessionStorage.getItem("freeCases") == "0") {
        setShowAlert(true)
      }
    }
    else {
      window.location.href = "/login"
    }
  }

    useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => setShowAlert(false), 2000);
      return () => clearTimeout(timer); 
    }
  }, [showAlert]);

  const handleFriendFight = async () => {

    if (sessionStorage.getItem("subscribed") === "true") {
      sessionStorage.setItem("caseId", id)

      // window.location.href = "/mooting"
    } else if (sessionStorage.getItem("subscribed") === "false") {
      await freeTrial()
      if (sessionStorage.getItem("loggedIn") && sessionStorage.getItem("freeCases") != "0") {

        sessionStorage.setItem("caseId", id)

        // window.location.href = "/mooting"
        setFriendPopover(true)
      } else if (sessionStorage.getItem("freeCases") == "0") {
        setShowAlert(true)
      }
      else {
        window.location.href = "/login"
      }
    }
  }

  const attributeIconMap: { [key: string]: any } = {
    Murder: faHandcuffs,
    Intoxication: faWineBottle,
    Affair: faHeartBroken,
    Rape: faSadCry,
    "Sexual Harrassment": faFaceGrinTongueWink,
    Smuggling: faBoxesStacked,
    Negligence: faUserDoctor,
    Kidnapping: faPerson,
    Manipulation: faHandsAslInterpreting,
  };


  const handleOpenModalImageGallery = () => {
    router(`${thisPathname}/?modal=PHOTO_TOUR_SCROLLABLE`);
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap border border-black !space-y-6 bg-creamyYellow">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <Badge name={type} />
          {/* <LikeSaveBtns /> */}
        </div>

        {showAlert && (
          <Alert children="No free trial remaining, kindly subscribe" type="warning" />
        )}

        {friendPopover && <PeerPopover onClose={handlePopoverClose} />}

        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {caseTitle}
        </h2>

        {/* 3 */}
        {/* <div className="flex items-center space-x-4">
          <StartRating />
          <span>·</span>
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> Tokyo, Jappan</span>
          </span>
        </div> */}

        <div className="w-full border-b  border-neutral-100 dark:border-neutral-700" />

        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-black dark:text-neutral-300">
          {attributes.map((attribute, index) => (
            <div key={index} className="flex items-center space-x-3">
              <FontAwesomeIcon icon={attributeIconMap[attribute]} />
              <span>
                <span className="hidden sm:inline-block">{attribute}</span>
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap bg-[#FFF4CC] border border-black">
        <div>
          <h4 className="text-lg font-semibold">AIM</h4>
          <span className="block mt-3 text-black dark:text-neutral-400">
            {aim}
          </span>
        </div>
        <div className="w-14 border-b border-black dark:border-neutral-700" />


        <h2 className="text-2xl font-semibold">Things to know</h2>
        <div className="w-14 border-b border-black dark:border-neutral-700" />

        <div>
          {caseLaws.map((caseLaw, index) => (
            <div key={index} className="flex items-center space-x-3">
              <span>
                <span className=" sm:inline-block">{caseLaw}</span>
              </span>
            </div>
          ))}
        </div>

        <div className="w-14 border-b border-black dark:border-neutral-700" />

        <h2 className="text-2xl font-semibold">Case Details</h2>
        <div className="w-14 border-b border-black dark:border-neutral-700"></div>
        <div className="text-black dark:text-neutral-300">
          <span>
            <DescriptionRenderer description={description} />
          </span>
        </div>
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap bg-creamyYellow border border-black">
       
        <div>
          <h4 className="text-lg font-semibold">How to Proceed</h4>
          
          <div className="step-card border border-black p-6 rounded-lg shadow-md mt-6">
  <h2 className="text-xl font-semibold text-black mb-4">
    1. At your fingertips
  </h2>
  <p className="text-black">
      You can analyze and prepare for the case at your convenience. When you're ready, you can fight the case against AI, a peer, or in teams.
  </p>
</div>

</div>


<div className="step-card border border-black p-6 rounded-lg shadow-md mt-6">
  <h2 className="text-xl font-semibold text-black mb-4">
    2. Analyze the Case
  </h2>
  <p className="text-black">
    Read the case thoroughly, understand the facts, sections involved, and the rulings. This will help you develop your legal strategy.
  </p>
</div>

<div className="step-card border border-black p-6 rounded-lg shadow-md mt-6">
  <h2 className="text-xl font-semibold text-black mb-4">
    3. Choose Your Side
  </h2>
  <p className="text-black">
    Pick your side: prosecutor or defense. Depending on the case, you will need to build your argument accordingly.
  </p>
</div>

<div className="step-card border border-black p-6 rounded-lg shadow-md mt-6">
  <h2 className="text-xl font-semibold text-black mb-4">
    4. Prepare for Battle
  </h2>
  <p className="text-black">
    Whenever you're ready, press "Fight with AI" to battle against our advanced AI model, "Friend" to compete against a peer, or "Teams" to compete in groups.
  </p>
</div>


        <div>
          <h4 className="text-lg font-semibold">Secrets</h4>
          <span className="block mt-3 text-black dark:text-neutral-400">
            Each character has a secret, which can be found out by asking the right questions.
          </span>
        </div>
        <div className="w-14 border-b border-black dark:border-neutral-700" />



        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Points</h4>
          <div className="mt-3 text-black dark:text-neutral-400 max-w-md text-sm sm:text-base">
            <span className="block mt-3 text-black dark:text-neutral-400">
              The arguments would be judged on the basis of:

              <li>
                Keywords
              </li>
              <li>Sections and Case laws used</li>
              <li>Arguments and logic provided</li>

            </span>
          </div>
        </div>
        <div className="w-14 border-b border-black dark:border-neutral-700" />
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl bg-peach max-w-md ">
        <div className="flex items-center">
                 {subscribed != null && subscribed === "false" && <div className="flex items-center">
          <span className="text-white text-xl font-bold bg-gradient-to-r from-red-500 to-yellow-500 py-1 px-3 rounded-full shadow-lg animate-pulse">
            Free Cases Remaining: <span className="text-3xl font-extrabold ml-2">{freeCases}</span>
          </span>
          </div>}
           {subscribed != null && subscribed === "true" && <div className="flex items-center">
          <span className="text-white text-xl font-bold bg-gradient-to-r from-red-500 to-yellow-500 py-1 px-3 rounded-full shadow-lg animate-pulse">
            PREMIUM
          </span>
        </div>}
        
        </div>
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">
            {caseTitle}
          </span>
          {/* <StartRating /> */}
        </div>

        <div className="flex flex-col space-y-4">
          <div className="flex justify-between text-black dark:text-neutral-300">
            {oneLineQues}
          </div>
        </div>


        <ButtonPrimary className="mb-2" onClick={handleFightNow}>Fight Against AI!</ButtonPrimary>

        {/* <div className="flex justify-between flex-row"> */}
         
          {/* <button className=" relative text-sm sm:text-base font-medium px-4 py-3 sm:px-6 rounded-full transition-colors text-white bg-fuchsia-600 hover:bg-fuchsia-800" onClick={handleFriendFight}>
            I have a session ID!
          </button> */}
          <button className=" relative text-sm sm:text-base font-medium px-4 py-3 sm:px-6 rounded-full transition-colors text-white bg-fuchsia-600 hover:bg-fuchsia-800" onClick={handleFriendFight}>
            Fight Against Friend!
          </button>
          
          {/* </div> */}

          
        <button
          className="relative text-sm sm:text-base font-medium px-4 py-3 sm:px-6 rounded-full transition-colors text-white bg-green-600 hover:bg-green-800"
          onClick={handleTeamFight}
        >
          Fight in Teams!
        </button>
        <p className="mt-2 text-sm text-black">
          If you want to join a team, please go to <a href="/lobby" className="text-white hover:underline">Playground</a>.
        </p>
     


      </div>
    );
  };

  return (
    <div className="nc-ListingStayDetailPage bg-creamyYellow">
      {/*  HEADER */}
      <header className="rounded-md sm:rounded-xl">
     
              {makeLobbyDirect && <CreateLobbyDirect
          onClose={handleJoinLobbyClose}
          setOpenTeamSelect={setOpenTeamSelect}
          setEnteredLobby={setEnteredLobby}
        ></CreateLobbyDirect>}

        {idealWayToGo && <IdealWayToGoPopover onClose={handleIdealWayPopoverClose} />}

        {isJoinLobbyOpen && <JoinLobbyPopover name={lobbyName} onClose={handleJoinLobbyClose} setEnteredLobby={setEnteredLobby} setOpenTeamSelect={setOpenTeamSelect}></JoinLobbyPopover>}

            {openTeamSelect && <TeamSelectPopover onClose={handleTeamSelectClose} hostedCourtName={enteredLobby}></TeamSelectPopover>}
            
        <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
          <div
            className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer "
            onClick={handleOpenModalImageGallery}
          >

            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div>

        </div>
      </header>

      {/* MAIN */}
      {!makeLobbyDirect &&
        <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
          {/* CONTENT */}
          <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
            
            {showSideSelectPopover && <SideSelectPopover onClose={handleSideSelectClose} caseId={id} />}
            {renderSection1()}
            {renderSection2()}
            {renderSection8()}
          </div>

          {/* SIDEBAR */}
          <div className=" lg:block flex-grow mt-14 lg:mt-0">
            <div className="sticky top-28">{renderSidebar()}</div>
          </div>
        </main>}
    </div>
  );
};

function ListCaseDetails() {

  const caseId = sessionStorage.getItem("caseId")
  const [details, setDetails] = useState<caseDetailsProp | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response = await axios.post(`${apiUri}/fetchCaseDetails`, { id: caseId });
        setDetails(response.data.data);
      } catch (error) {
        console.error('Error fetching case details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, [caseId]);

  if (loading) {
    return <LoadingIcon text="Loading Cases"/>;
  } else if (details) {
    return <CaseDetails {...details} />;
  } else {
    return <p>Error loading case details.</p>;
  }

}

export default ListCaseDetails
