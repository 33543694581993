import React, { FC, useRef, useEffect, useState } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import Button from 'shared/Button/Button';
import { init, animateCamera, toggleCharacterVisibility } from 'containers/PageCourt/threeUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faRobot, faMicrophone, faBrain, faNotesMedical } from '@fortawesome/free-solid-svg-icons';
import NotesPopover from 'components/CaseTools/NotesPopover';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import IpcBotPopover from 'components/CaseTools/IPCbot/IpcBotPopover';
import MessageBox from 'containers/PageCourt/messageBox';
import { Message } from 'containers/PageCourt/types';
import CaseDetailsPopover from 'components/CaseTools/CaseDetailsPopover';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { apiUri } from '../../constants';
import { Alert } from 'shared/Alert/Alert';
import TutorialPopover from 'containers/Tutorial/Tutorial';
import LoadingButton from 'components/Loading/LoadingButton';
import Sidebar from 'components/Sidebar/Sidebar';
import RestP2P from 'containers/CaseDetails/RestP2PcasePopover';
import io, { Socket } from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import TeamChat from './TeamChat';
import { TeamMessage } from './types';
import axios from 'axios';

interface MessageType {
  content: string;
  groupId: string;
  senderId: string;
}


const SERVER_URL = apiUri;
const PageCollegeMootcourt: FC<{}> = () => {
  const socket: Socket = io(`${apiUri}`);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const sceneRef = useRef<THREE.Scene>();
  const cameraRef = useRef<THREE.PerspectiveCamera>();
  const rendererRef = useRef<THREE.WebGLRenderer>();
  const controlsRef = useRef<OrbitControls>();
  const textSpriteRef = useRef<THREE.Sprite>();
  const textSpriteRefLawyer2 = useRef<THREE.Sprite>();
  const witnessCharacterRef = useRef<THREE.Object3D | null>(null);
  const statsRef = useRef<Stats>();
  const [lawyer2Text, setLawyer2Text] = useState<string>('');
  const conversationLogRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [fullUserConversation, setFullUserConversation] = useState<string>('');
  const [isTutorialOpen, setisTutorialOpen] = useState(true);

  const [isNotesPopoverOpen, setIsNotesPopoverOpen] = useState(false);
  const [isCaseDetailsPopoverOpen, setisCaseDetailsPopoverOpen] = useState(false);
  const [isBotPopoverOpen, setIsBotPopoverOpen] = useState(false);
  const [input, setInput] = useState<string>('');
  const [isListening, setIsListening] = useState<boolean>(false);
  const { transcript, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();
  const [witnessInStand, setWitnessInStand] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const [analysisReady, setAnalysisReady] = useState(false);
  const [sessionId, setSesssionId] = useState("")

  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const [sideMenuIsExpand, setSideMenuIsExpand] = useState(false);
  const [isExpand, setIsExpand] = useState(false);
  const [isTeamChatExpand, setIsTeamChatExpand] = useState(false);
  const [teamChatIsExpand, setTeamChatIsExpand] = useState(false)
  const [isRestP2P, setIsRestP2P] = useState(false);
  const [fullFriendConversation, setFullFriendConversation] = useState('');

  const [teamMessages, setTeamMessages] = useState<TeamMessage[]>([]);
  const teamMessageConversationLogRef = useRef<HTMLDivElement>(null);
  const socketRef = useRef<Socket | null>(null);
  const navigate = useNavigate();

  const hostedCourtName = sessionStorage.getItem("hostedCourtName");
  const teamName = sessionStorage.getItem("teamName");

  let userId: string | null = '';
  let userIdFriend: string | null = '';

  const handleRemoveCourt = async () => {
    //console.log("HANDLE remove court called");
    const response = await axios.post(`${apiUri}/removeHostedCourt`, {
      hostedCourtName: hostedCourtName,
      //   teamName: teamName,
    })
    // //console.log("RESPONSE REceived for handle remove court");
    if (response.data.success) {
      //console.log('Successfully removed court.')
    }
    else {
      //console.log('Failed to remove court.')
    }
    // //console.log("Socket disconnected");
  };


  const handleDisconnect = async () => {
    //console.log("HANDLE disconnect called");
    // const response = await axios.post(`${apiUri}/removeUserFromHostedCourt`, {
    //   hostedCourtName: hostedCourtName,
    //   teamName: teamName,
    //   userId: userId,
    // })
    // //console.log("RESPONSE REceived for handle disconnection");
    // if (response.data.success) {
    //   //console.log('Successfully removed user from hosted court.')
    // }
    // else {
    //   //console.log('Failed to remove user from hosted court.')
    // }
    //console.log("Socket disconnected");
  };

  useEffect(() => {
    init(sceneRef, cameraRef, canvasRef, rendererRef, controlsRef, textSpriteRef, textSpriteRefLawyer2, statsRef, witnessCharacterRef);
    return () => {
      // handleDisconnect()
      socket.disconnect();
    }

  }, []);


  useEffect(() => {
    setMessages(getStoredMessages());
  }, []);


  useEffect(() => {
    socketRef.current = socket;

    const handleConnect = () => {
      //console.log("Socket connected:", socket.id);
      setupSocket();  // Call your setup function when the socket connects
    };


    socket.on('connect', handleConnect);
    // socket.on('disconnect', handleDisconnect);

    // Initial check for already connected socket (if it connects before event listeners are set up)
    if (socket.connected) {
      handleConnect();
    }

    // Cleanup on component unmount
    // return () => {

    //   socket.off('connect', handleConnect);
    //   socket.off('disconnect', handleDisconnect);
    //   // socket.disconnect();
    // };
  }, [])


  const connect = async (setMessages: React.Dispatch<React.SetStateAction<Message[]>>): Promise<Socket | null> => {

    try {

      //console.log("CONNECT called");
      //console.log(socket.id);
      // socket.emit('connection');
      userId = sessionStorage.getItem('email')
      if (userId == '') {
        console.error("Email not found");
      }
      socket.emit('connectTeam', hostedCourtName, teamName);

      socket.on('receiveTeam', (teamData) => {
        if (teamData.senderId != userId)
          setTeamMessages((prevTeamMessages) => [...prevTeamMessages, { senderId: teamData.senderId, message: teamData.message }]);
      });

      socket.on('receiveCourt', (courtData) => {
        if (courtData.senderId != teamName)
          setMessages((prevMessages) => [...prevMessages, { role: teamName == "Prosecutor" ? "Defendant" : "Prosecutor", text: courtData.message }]);
        // setFullUserConversation((prevConversation) => `${prevConversation} ${courtData.message}`)
        if (courtData.senderId == "Judge") {
          const state = courtData.message;
          // handleRemoveCourt();
          navigate("/p2pAnalytics", { state });
        }
        if (courtData.message === 'i rest my case') {
          setIsRestP2P(!isRestP2P);
        }
        setFullFriendConversation((prevFriendConversation) => `${prevFriendConversation} ${courtData.message}`);
      })
      // socket.emit('join group', sessionId);
      // socket.on('messageReceived', (message) => {
      //   //console.log(message);
      //   if (message.senderId != userId) {
      //     userIdFriend = message.senderId;
      //     if (typeof message.content === 'string') {
      //       setMessages((prevMessages) => [...prevMessages, { role: "User2", text: message.content }]);
      //       setFullFriendConversation((prevConversation) => `${prevConversation} ${message.content}`)
      //       if (message.content === 'i rest my case') {

      //         setIsRestP2P(!isRestP2P);
      //       }
      //     }
      //     else {
      //       //console.log("OBJECT received");
      //       const state = message.content;
      //       navigate("/p2pAnalytics", { state });
      //     }
      //   }
      // })


      return socket;
    } catch (error) {
      //console.log('Socket error', error);
      return null;
    }
  };

  useEffect(() => {
    if (conversationLogRef.current) {
      conversationLogRef.current.scrollTop = conversationLogRef.current.scrollHeight;
    }
  }, [lawyer2Text]);

  useEffect(() => {
    //console.log("VALUE OF WITNESS IS:" + witnessInStand);
    toggleCharacterVisibility(witnessInStand, witnessCharacterRef, sceneRef, cameraRef, rendererRef);
  }, [witnessInStand]);

  const caseActive: string = sessionStorage.getItem("caseId") || "";

  const getStoredMessages = (): Message[] => {
    const storedMessages = sessionStorage.getItem('messages');
    return storedMessages ? JSON.parse(storedMessages) : [];
  };


  useEffect(() => {
    if (transcript && !isListening) {
      setInput(transcript);
      //console.log(fullUserConversation);
      resetTranscript();
    }
  }, [transcript, isListening, fullUserConversation, resetTranscript]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    if (messages.length > 0) {
      if (messages[messages.length - 1].role === "judge") {
        animateCamera(cameraRef, controlsRef, new THREE.Vector3(-2, 1, -0.3), new THREE.Vector3(-4, 1, -0.3), 3000);
      } else if (messages[messages.length - 1].role === "lawyer") {
        animateCamera(cameraRef, controlsRef, new THREE.Vector3(-1, 2, -3), new THREE.Vector3(-3.55, 0, -1), 3000);
      } else {
        animateCamera(cameraRef, controlsRef, new THREE.Vector3(-1, 2, 3), new THREE.Vector3(-3.55, 0, 1), 3000);
      }
    }
  }, [messages]);

  useEffect(() => {
    sessionStorage.setItem('messages', JSON.stringify(messages));
  }, [messages]);

  const startVoiceRecognition = () => {
    if (browserSupportsSpeechRecognition) {
      SpeechRecognition.startListening({ continuous: true });
      setIsListening(true);
    } else {
      console.error('Browser does not support speech recognition.');
    }
  };

  const handleVoiceInput = () => {
    startVoiceRecognition();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value);
    resetTranscript();
  };

  const handleMicEnd = () => {
    SpeechRecognition.stopListening();
    setIsListening(false);
  };

  useEffect(() => {
    sessionStorage.setItem("fullUserConversation", fullUserConversation);
  }, [fullUserConversation]);
  useEffect(() => {
    sessionStorage.setItem("fullFriendConversation", fullFriendConversation);
  }, [fullFriendConversation]);

  useEffect(() => {
    if (input === "i rest my case") {
      handleRestMyCase();
    }
  }, [input]);

  const handleSendMessage = () => {
    if (input.trim() === '') {
      return;
    }

    setMessages((prevMessages) => [...prevMessages, { role: 'User', text: input }]);
    setFullUserConversation((prevConversation) => `${prevConversation} ${input}`);
    if (socket) {
      socket.emit('sendCourt', hostedCourtName, teamName, input, userId);
    }
    setInput('');
  };

  const setupSocket = async () => {
    //console.log("SETUP SOCKET CALLED");
    socketRef.current = await connect(setMessages);
  };



  const handleSendTeamMessage = (message: string) => {
    if (input.trim() === '') {
      return;
    }

    setTeamMessages((prevTeamMessages) => [...prevTeamMessages, { senderId: userId ?? '', message: message }]);
    socket.emit("sendTeam", hostedCourtName, teamName, message, userId);
  };

  const handleButtonClick = () => {
    setIsNotesPopoverOpen(true);
  };

  const handleClosePopover = () => {
    setIsNotesPopoverOpen(false);
  };

  const handleBotClosePopover = () => {
    setIsBotPopoverOpen(false);
  };

  const handleBotClick = () => {
    setIsBotPopoverOpen(true)
  }

  const handleTutorialPopover = () => {
    setisTutorialOpen(false)
  }

  const handleDetailClick = () => {
    setisCaseDetailsPopoverOpen(true)
  }

  const handleCLoseDetailsPopover = () => {
    setisCaseDetailsPopoverOpen(false)
  }

  const handleQuestionWitness = () => {
    setInput("i want to question the witness")
    handleSendMessage()
  }


  const handleRestMyCase = async () => {
    await handleSendMessage()
    setAnalysisReady(true)
  }

  const getSessionId = () => {
    const sessionId = sessionStorage.getItem('sessionId');
    if (sessionId) {
      setSesssionId(sessionId);
      return sessionId;
    }
  };

  const handleAnalytics = () => {
    window.location.href = "/analysis"
  }

  const handleExitCourt = async () => {
    <Alert children="Ending court session and directing to analytics" type="success" />
    setTimeout(function () {
      window.location.href = "/feedback";
    }, 2000);
  }
  // const handleCompnentRebuild = () => {
  //   //console.log("COMPONENT REBUILD");
  // }
  // handleCompnentRebuild();
  return (
     <div className="relative bg-creamyYellow min-h-screen md:flex">
      {/* sidemenu */}
      <Sidebar isExpand={isExpand} setIsExpand={setIsExpand} setExpand={setSideMenuIsExpand} messages={messages} conversationLogRef={conversationLogRef} />
      {/* content */}
      <div
        className={`flex-1 min-h-screen mx-0 bg-slate-100 transition-all duration-300 ease-in-out ${sideMenuIsExpand ? "md:ml-72" : "md:ml-20"
          }`}
      >
        <div className="flex flex-col">
          {isTutorialOpen && <TutorialPopover onClose={handleTutorialPopover} />}
          <div className=" flex flex-col lg:flex-row">
            <div className="flex flex-col h-1/2 items-center lg:h-full w-full lg:w-3/4">
              {lawyer2Text &&
                <MessageBox characterName={messages[messages.length - 1].role === "judge" ? "Judge" : "Prosecutor"} message={lawyer2Text}></MessageBox>}


              <canvas ref={canvasRef} className="w-full lg:w-1/2 h-1/2"></canvas>

            </div>


            <div className="flex flex-col w-full lg:w-1/4 z-10 bg-peach border border-black p-4">
              
                   <div className="flex items-center space-x-2 bg-gray-100 mb-3 p-2 rounded-lg shadow-md">
                <span className="font-semibold text-gray-700">Lobby Name</span>
                <span className="text-gray-900 font-mono">{hostedCourtName}</span>
              </div>

              <div className="flex items-center space-x-2 bg-gray-100 mb-3 p-2 rounded-lg shadow-md">
                <span className="font-semibold text-gray-700">Team</span>
                <span className="text-gray-900 font-mono">{teamName}</span>
              </div>
              
              <textarea className='mb-2 w-full border border-black justify-center px-4 h-[10rem] rounded-md' name="" id="" placeholder="Enter your text" value={input} onChange={(event) => { handleInputChange(event) }}></textarea>
              
              {isProcessing && <LoadingButton />}
              
              <Button
                className="text-neutral-700 dark:text-neutral-200  border border-black  hover:bg-green-400  bg-green-300 mb-2 transition duration-300 ease-in-out transform hover:scale-105"
                onClick={handleSendMessage}
              >
                Send
              </Button>

              
              
              <Button
                className="text-neutral-700  border border-black  hover:bg-gray-200 dark:text-neutral-200 bg-creamyYellow mb-2 transition duration-300 ease-in-out transform hover:scale-105 flex items-center"
                onClick={handleVoiceInput}
                disabled={isListening}
              >
                <FontAwesomeIcon icon={faMicrophone} className="mr-2" />
                Microphone
              </Button>
              {isListening && (
                <div className="flex items-center border border-black justify-center p-2 border border-black rounded bg-white">
                  <span className="animate-pulse">Listening...</span>
                  <Button
                    className="text-neutral-700   hover:bg-gray-200 dark:text-neutral-200 bg-red-400 mb-4 flex items-center"
                    onClick={handleMicEnd}
                  >
                    <FontAwesomeIcon icon={faMicrophone} className="mr-2" />
                    Stop
                  </Button>
                </div>
              )}


              <Button
                className="text-neutral-700 border border-black  hover:bg-gray-200 dark:text-neutral-200 bg-creamyYellow mb-2 flex items-center transition duration-300 ease-in-out transform hover:scale-105"
                onClick={handleDetailClick}>
                <FontAwesomeIcon icon={faBook} className="mr-2" />
                Case Details
              </Button>
              {isCaseDetailsPopoverOpen && <CaseDetailsPopover onClose={handleCLoseDetailsPopover} />}
              <Button
                className="text-neutral-700 dark:text-neutral-200  border border-black hover:bg-gray-200 bg-creamyYellow mb-2 flex items-center transition duration-300 ease-in-out transform hover:scale-105"
                onClick={handleBotClick}
              >
                <FontAwesomeIcon icon={faRobot} className="mr-2" />
                Avyukta Bot
              </Button>
              {isBotPopoverOpen && <IpcBotPopover onClose={handleBotClosePopover} />}
              <Button className="text-neutral-700 border border-black hover:bg-gray-200 dark:text-neutral-200 bg-creamyYellow mb-2 transition duration-300 ease-in-out transform hover:scale-105 flex items-center" onClick={() => { setIsExpand(!isExpand) }}>
                <FontAwesomeIcon icon={faBrain} className="mr-2" />
                Logs
              </Button>
              
              <Button
                className="text-neutral-700  border border-black hover:bg-gray-200  dark:text-neutral-200 transition duration-300 ease-in-out transform hover:scale-105 bg-creamyYellow mb-2 flex items-center"
                onClick={handleButtonClick}
              >
                <FontAwesomeIcon icon={faNotesMedical} className="mr-2" />
                Notes
              </Button>
              {isNotesPopoverOpen && <NotesPopover onClose={handleClosePopover} />}

                       {isRestP2P && <RestP2P
                onClose={() => { setIsRestP2P(!isRestP2P); }}
                socket={socket}
                sessionId={sessionId}
                userId={teamName!}
                userIdFriend={teamName == "Prosecutor" ? "Defendant" : "Prosecutor"}
                fullUserConversation={fullUserConversation}
                fullFriendConversation={fullFriendConversation}
              />}

              <ButtonPrimary
                onClick={() => {
                  setInput("i rest my case");

                }}
                className="bg-red-500 text-white rounded-lg shadow-lg px-6 py-2 mb-4 transition duration-300 ease-in-out transform hover:scale-105 hover:bg-red-700"
              >
                Get Judgement
              </ButtonPrimary>

              <TeamChat setTeamMessages={setTeamMessages} socket={socket} isExpand={isTeamChatExpand} setIsExpand={setIsTeamChatExpand} setExpand={setTeamChatIsExpand} messages={teamMessages} teamMessageConversationLogRef={teamMessageConversationLogRef} />

            </div >
          </div>

        </div>
      </div>
    </div>
  );
    
};

export default PageCollegeMootcourt;
