import React, { FC, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiUri } from "../../constants";
import { render } from "@testing-library/react";
import { Alert } from "shared/Alert/Alert";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

export interface PageSignUpProps {
  className?: string;
}

// const loginSocials = [
//   {
//     name: "Continue with Facebook",
//     href: "#",
//     icon: facebookSvg,
//   },
//   {
//     name: "Continue with Twitter",
//     href: "#",
//     icon: twitterSvg,
//   },
//   {
//     name: "Continue with Google",
//     href: "#",
//     icon: googleSvg,
//   },
// ];

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [phone, setPhoneNumber] = useState('')
  const [isOtpSent, setisOtpSent] = useState(false)
  const [otp, setOtp] = useState('')
  const [unableToSignup, setUnableToSignup] = useState<boolean>(false)
  

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!isEmailValid) {
      return;
    }
    try {
      const response = await axios.post(`${apiUri}/signup`, {
        "email": email, "password": password, "phoneNumber": phone, "otpCode": otp
      });
      //console.log('Server response:', response.data);
      if (response.data.success) {
        window.location.href = '/login'
      } 

    } catch (error) {
      console.error('Error posting form data:', error);
      setUnableToSignup(true)
    }
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setIsEmailValid(validateEmail(emailValue));
  };

  const handleSendOtp = async () => {

    try {
      const response = await axios.post(`${apiUri}/sendOtp`, { "phoneNumber": phone });
      //console.log('Server response:', response.data);
     
      setisOtpSent(true)
    } catch (error) {
      console.error('Error posting form data:', error);
      <Alert children="Unable to send OTP. Kindly try again later." type="error" />
    }
  }

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Sign Up
         
        </h2>
        <h2 className="my-10 flex items-center animate-pulse text-xl leading-[115%] md:text-xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Get any 10 cases as a free trial.
        </h2>
        {isOtpSent && <Alert children="OTP sent. Kindly check your registered mobile number." type="success" />}
        
        {unableToSignup && <Alert children="Email or mobile already exist." type="success" />}
        {/* { <div className="max-w-md mx-auto space-y-6 "> */}

        {/* <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a> */}
        {/* ))} */}
        {/* </div> } */}
        {/* OR */}


        {/* FORM */}
        <form className="grid grid-cols-1 gap-6 max-w-md mx-auto" onSubmit={handleSubmit}>
          <label className="block">
            <span className="text-neutral-800 dark:text-neutral-200">
              Email address
            </span>
            <input
              type="email"
              placeholder="example@example.com"
              className={`mt-1 ${isEmailValid ? 'border-green-500' : 'border-red-500'} border`}
              value={email}
              onChange={handleEmailChange}
            />
          </label>
          <label className="block">
            <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
              Password
            </span>
            <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="mt-1" />
          </label>
          <label className="block">
            <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
              Phone number
            </span>
            <PhoneInput
              placeholder="Enter phone number"
              value={phone}
              defaultCountry="IN"
              onChange={(e) => {
                setPhoneNumber(e ? e.toString() : '')
              }} />
          </label>

          {isOtpSent ? <div> 
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Enter OTP
              </span>
              <Input type="number" value={otp} onChange={(e) => setOtp(e.target.value)} className="mt-1" />
            </label>
            <br/>
            <ButtonPrimary type="submit">Sign In</ButtonPrimary>
          </div> :

            <ButtonPrimary type="button" onClick={handleSendOtp}>Send Otp</ButtonPrimary>
          }

        </form>

        {/* ==== */}
        <span className="block text-center text-neutral-700 dark:text-neutral-300">
          Already have an account? {` `}
          <Link className="text-blue-600" to="/login">Sign in</Link>
        </span>
      </div>
    </div>
    // </div>
  );
};

export default PageSignUp;
