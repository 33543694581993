import React, { FC, useState } from "react";
import "./messageBox.css";
import { MessageBoxProps } from "./types";
import textToSpeech from "./textToSpeech";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faEarListen} from '@fortawesome/free-solid-svg-icons';


const MessageBox: FC<MessageBoxProps> = ({ characterName, message }) => {

  const [isPulsating, setIsPulsating] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false)

    const handleMicClick = () => {
      setIsPulsating(true)
      setIsSpeaking(true)
    textToSpeech(message);
  };

  return (
 <div className="flex flex-col h-48 p-5 bg-opacity-80 bg-creamyYellow  border-2 border-black rounded-lg text-black font-sans shadow-lg transition-opacity duration-300 ease-in-out">
  <div className="text-lg font-bold mb-2">
        <span>{characterName}</span>
        {/* {!isSpeaking &&
    <button
      onClick={handleMicClick}
      className={`ml-4 ${isPulsating ? 'animate-pulse' : ''}`}
    >
           <FontAwesomeIcon icon={faEarListen} className="w-6 h-6 text-black" />
      <span className="ml-3">Click to Listen</span>
    </button>
  } */}
  </div>
  <div className="flex-grow overflow-auto text-base">
    <p>{message}</p>
  </div>
</div>

  );
};

export default MessageBox;
