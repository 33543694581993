import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import PostCardMeta from "components/PostCardMeta/PostCardMeta";
import { PostPosterType } from "data/types";
import { Link } from "react-router-dom";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";
import PostTypeFeaturedIcon from "components/PostTypeFeaturedIcon/PostTypeFeaturedIcon";
import { Badge } from "lucide-react";

export interface Card3Props {
  className?: string;
  post: PostPosterType;
}

const Card3: FC<Card3Props> = ({ className = "h-full", post }) => {
  console.log(post)
  const { id, title, href, featuredImage, desc, category, postType } = post;

  // console.log(id)
  // console.log(title)

  const handleRedirection = () => {
    sessionStorage.setItem("blogId", id)

    window.location.href = href
  }

  return (
    <div
      className={`nc-Card3 relative flex flex-col-reverse sm:flex-row sm:items-center rounded-[40px] group ${className}`}
      data-nc-id="Card3"
    >
      <div className="flex flex-col flex-grow">
        <div className="space-y-5 mb-4">
          <Badge
          name={category}
          
        />
          <div>
            <h2
              className={`nc-card-title block font-semibold text-neutral-900 dark:text-neutral-100 text-xl`}
            >
              <button onClick={handleRedirection} className="text-black line-clamp-2">
                {title}
              </button>
            </h2>
            <div className="hidden sm:block sm:mt-2">
              <span className="text-neutral-500 dark:text-neutral-400 text-base line-clamp-1">
                {desc}
              </span>
            </div>
          </div>

           <PostCardMeta meta={{ ...post }} /> 
        </div>
      </div>

      <div
        className={`block flex-shrink-0 sm:w-56 sm:ml-6 rounded-3xl overflow-hidden mb-5 sm:mb-0`}
      >
        <button
          onClick={handleRedirection}
          className={`block w-full h-0 aspect-h-9 sm:aspect-h-16 aspect-w-16 `}
        >
          <NcImage
            containerClassName="absolute inset-0"
            src={featuredImage}
            alt={title}
          />
          <span>
            <PostTypeFeaturedIcon
              className="absolute left-2 bottom-2"
              postType={"standard"}
              wrapSize="w-8 h-8"
              iconSize="w-4 h-4"
            />
          </span>
        </button>
      </div>
    </div>
  );
};

export default Card3;
