import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { apiUri } from '../../constants';
import { motion } from 'framer-motion';
import LoadingIcon from 'components/Loading/LoadingIcon';
import Alert from 'shared/Alert/Alert';
import { Eye, Copy, Bookmark, ChevronDown, ChevronUp } from 'lucide-react';
import {  Search, FileUp, Book, MessageSquare } from 'lucide-react';
import ViewFullCasePopover from './ViewFullCasePopover';
import InteractWithCase from './InteractionPopover';

type CaseData = {
    "Case Title": string;
    "Parties involved": string[]
    "case laws and sections referred": string[]
    "facts of the case": string[]
    "arguments of both parties":object
    "final decision": string
}

const LoadingCard: React.FC = () => (
    <motion.div
        className="rounded-lg shadow-md p-6 mb-4 bg-gray-100"
        animate={{ opacity: [0.5, 1, 0.5] }}
        transition={{ repeat: Infinity, duration: 1.5, ease: "easeInOut" }}
    >
        <div className="h-6 bg-gray-300 rounded mb-4"></div>
        <div className="h-4 bg-gray-300 rounded mb-2"></div>
        <div className="h-4 bg-gray-300 rounded mb-2"></div>
        <div className="h-4 bg-gray-300 rounded"></div>
    </motion.div>
);

const CaseCard: React.FC<{ caseData: CaseData, index:number, fullCaseFiles: string[], className?:string }> = ({ caseData,index,fullCaseFiles ,className = ''}) => {
      const [expandedSection, setExpandedSection] = useState<string | null>("facts of the case");

    
    const [allExpanded, setAllExpanded] = useState(false);

    // console.log(index)

    
    console.log(fullCaseFiles)
    const toggleSection = (section: string) => {
        setExpandedSection(expandedSection === section ? null : section);
    };

    const [showFullCasePopover, setShowFullCasePopover] = useState(false)
    const [indexOfdocumentToShow, setindexOfDocumentToShow] = useState(0)
    const [showInteractionPopover, setShowInteractionPopover] = useState(false)

    
    const toggleAllSections = () => {
        if (allExpanded) {
            setExpandedSection(null); 
        } else {
            setExpandedSection("all"); 
        }
    setAllExpanded(!allExpanded);
    setExpandedSection(allExpanded ? null : 'all');
  };

    
    const renderSectionContent = (section: string) => {
         const content = caseData[section as keyof CaseData];

    if (Array.isArray(content)) {

      return content.map((item, index) => (
        <p key={index} className="text-sm mb-2">
          {item}
        </p>
      ));
    }

    if (typeof content === 'object') {
  
      return Object.entries(content).map(([key, value]) => (
        <div key={key} className="mb-2">
          <h4 className="font-medium">{key}</h4>
          <p className="text-sm">{value}</p>
        </div>
      ));
    }


    return <p className="text-sm">{content}</p>;
    };

    const handleViewFullCase = () => {
        setShowFullCasePopover(true)
        setindexOfDocumentToShow(index)
    }

     const handleViewFullCaseClose = () => {
        setShowFullCasePopover(false)
        }

    
    const handleInteractionClose = () => {
        setShowInteractionPopover(false)
    }

    const handleShowInteractionPopover = () => {
        setShowInteractionPopover(true)
    }

    return (
        
        <motion.div
            className={`rounded-lg shadow-md p-6 mb-4 ${className}`}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
        >
            <div className="flex justify-between items-start mb-4">
                <h2 className="text-xl font-semibold">{caseData['Case Title']}</h2>
                <div className="flex items-center space-x-2">
                
                    <Eye 
                        className="w-5 h-5 text-gray-400 cursor-pointer" 
                        onClick={toggleAllSections} 
                    />
                </div>
            </div>

            {showFullCasePopover && <ViewFullCasePopover onClose={handleViewFullCaseClose} document={fullCaseFiles[index]} />}

            {showInteractionPopover && <InteractWithCase onClose={handleInteractionClose} document={fullCaseFiles[index]} />}

            {Object.keys(caseData).map((section) => (
                section !== 'Case Title' && (
                    <div key={section} className="mb-4">
                        <button
                            className="flex justify-between items-center w-full text-left font-medium text-lg text-gray-700 hover:text-gray-900"
                            onClick={() => toggleSection(section)}
                        >
                            {section}
                            {expandedSection === section || expandedSection === 'all' ? (
                                <ChevronUp className="w-5 h-5" />
                            ) : (
                                <ChevronDown className="w-5 h-5" />
                            )}
                        </button>
                        {(expandedSection === section || expandedSection === 'all') && (
                            <div className={`mt-2 pl-4 border-l-2 border-gray-200`}>
                                {renderSectionContent(section)}
                            </div>
                        )}
                    </div>
                )
            ))}

                    <div className="flex justify-end space-x-2 bg-gray-50">
                <button
                    className="border border-black text-black bg-transparent hover:bg-black hover:text-white font-semibold py-2 px-4 rounded transition duration-300"
                onClick = {handleViewFullCase}>
                    <Book className="w-4 h-4 mr-2" />
                    
            View Full Case
          </button>
                <button
                    className="bg-black text-white font-semibold py-2 px-4 rounded hover:bg-gray-800 transition duration-300"
                 onClick = {handleShowInteractionPopover}>
                   
            <MessageSquare className="w-4 h-4 mr-2" />
            Interact with Case
          </button>
            </div>
            
        </motion.div>

      
    );
};

const Research: React.FC<{}> = () => {
    const [query, setQuery] = useState('')
    const [pageNum, setPageNum] = useState(0)
     const [caseData, setCaseData] = useState<CaseData[]>([])
    const [isLoading, setIsLoading] = useState(false);
    var FinalInfor: CaseData[] = [];

    const [fullCaseFile, setFullCaseFile] = useState<string[]>([])
    const [pdfFile, setPdfFile] = useState<File | null>(null);
    const [alert, setAlert] = useState(false)
    const email = sessionStorage.getItem("email") || ""

    if (sessionStorage.getItem("email") == "" || sessionStorage.getItem("email") == null) {
        window.location.href = "/login"
    }

       
    
    async function processQueries(queries: string[]) {
        
        // setIsLoading(true);
        setCaseData(new Array(queries.length * 2).fill(null));

        setFullCaseFile(new Array(queries.length * 2).fill(null))

        let cardIndex = -1;

        for (let i = 0; i < queries.length; i++) {
            try {
                const response = await axios.post(`${apiUri}/searchKanoon`, {
                    query: queries[i],
                    Num: pageNum
                });

                const caseDocs = response.data.data.docs;

                for (let j = 0; j < Math.min(2, caseDocs.length); j++) {
                    try {
                        const caseDetailCurrent = await axios.post(`${apiUri}/fetchdoc`, {
                            docId: caseDocs[j].tid
                        });

                        const doc = caseDetailCurrent.data.data.doc;
                        setFullCaseFile((fullCaseFile) => {
                            const updatedFile = [...fullCaseFile];
                            updatedFile[i * 2 + j] = doc 
                            
                            return updatedFile;
                        });
                       
                       console.log(doc)
                        console.log(fullCaseFile)
                        const MAX_SEGMENT_SIZE = 80000;

                        const segments: string[] = [];
                        let currentIndex = 0;

                        while (currentIndex < doc.length) {
                            const segment = doc.slice(currentIndex, currentIndex + MAX_SEGMENT_SIZE);
                            segments.push(segment);
                            currentIndex += MAX_SEGMENT_SIZE;
                        }

                        const extractions = await Promise.all(segments.map(segment => 
                            axios.post(`${apiUri}/extractor`, { detail: segment })
                        ));

                        const CombinedInfo = await axios.post(`${apiUri}/combinator`, {
                            segment: extractions.map(e => e.data.data)
                        });

                        setCaseData(prevData => {
                            const newData = [...prevData];
                            newData[i * 2 + j] = CombinedInfo.data.data[0];
                            return newData;
                        });

                        cardIndex++; 
                    } catch (error) {
                        console.log("Error during fetching doc: ", error);
                   
                    }

                
                }

                
            } catch (error) {
                console.log('Error during search:', error);
            }
        }


        setIsLoading(false);
    }
    
    
    const handleSearch = async (e: React.FormEvent) => {
        e.preventDefault();
        // setIsLoading(true);

        try {
            var whatToSearch: string[] = []
             try {
                const r = await axios.post(`${apiUri}/mindSearcher`, {
                    caseInput: query
                });
                 
                 whatToSearch = r.data.data
                 
                 //console.log(whatToSearch)
            } catch (error) {
                //console.log("Error deriving what to search: ", error)
             }
            

            const queries = whatToSearch

            // console.log("QUERIES TO SEARCH", queries)

            // console.log("EXTRACTEDDD", query)

            await processQueries(queries)
           


        } catch (error) {
            console.error('Error fetching search results:', error);
        }
    };

    const convertToString = (value: any): string => {
        return typeof value === 'object' ? JSON.stringify(value, null, 2) : value;
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setPdfFile(e.target.files[0]);
        }
    };

    async function summarizeDocument(pageContent: string) {
    const chunkSize = 100000;
    let summaries: string[] = [];

    
    for (let i = 0; i < pageContent.length; i += chunkSize) {
        const chunk = pageContent.substring(i, i + chunkSize);

        try {

        const response = await axios.post(`${apiUri}/documentSummarizer`, {
            caseInput: chunk,
        });


        if (response.data) {
            summaries.push(response.data.data);
        } else {
            //console.log(`No summary returned for chunk ${i / chunkSize + 1}`);
        }
        } catch (error) {
        console.error(`Error summarizing chunk ${i / chunkSize + 1}:`, error);
        }
    }
    return summaries.join(' ');
}

   const handleIngest = async (e: React.FormEvent) => {
        e.preventDefault();

       if (pdfFile) {
            // setIsLoading(true)
            try {
                const formData = new FormData();
                formData.append('document', pdfFile);

                const response = await axios.post(`${apiUri}/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                //console.log('Response:', response);
            } catch (error) {
                //console.log('Error:', error);
            }
       } else {
            setAlert(true)
       }
       
    //    setIsLoading(false)
   };
      useEffect(() => {
    if (alert) {
      const timer = setTimeout(() => setAlert(false), 2000);
      return () => clearTimeout(timer); 
    }
  }, [alert]);
    
    const handleResearchFile = async (e: React.FormEvent) => {
        e.preventDefault();

       if (pdfFile) {
            // setIsLoading(true)
            try {
                const formData = new FormData();
                formData.append('document', pdfFile);

                formData.append('email', email)

                const response = await axios.post(`${apiUri}/pdfExtract`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                //console.log(response.data.data)
                const j = JSON.stringify(response.data.data, null)
                const obj = JSON.parse(j)
           
                if (obj.pageContent) {
               
                const formattedContent = obj.pageContent
                    .replace(/\s+/g, ' ') 
                    .trim(); 

                obj.pageContent = formattedContent;
                }

                const summrizedContent = await summarizeDocument(obj.pageContent)
                
                setQuery(summrizedContent)

                handleSearch(e)
            } catch (error) {
                //console.log('Error:', error);
            }
        } else {
            setAlert(true)
       }
       
     
   };


     return (
    <div className="container mx-auto p-6 space-y-8">
             {alert && <Alert>{alert}</Alert>}
          
<div className="bg-gradient-to-r from-gray-900 to-black text-white p-8 rounded-lg shadow-2xl">
  <div className="mb-6">
    <h1 className="text-4xl font-bold mb-2 text-gray-100">LawBin Hood: Legal Case Research</h1>
    <div className="w-16 h-1 bg-blue-500 rounded"></div>
  </div>
  <div>
    <p className="text-lg text-gray-300 leading-relaxed">
      Enter the facts of your current case or upload a document, and our advanced software will search through more than 100 past cases to present the 10 most relevant ones for you.
    </p>
    <p className="text-md mt-6 text-gray-400 font-light">
      Please note, the full loading process can take up to 5 minutes.
    </p>
  </div>
</div>




      <div className="bg-white p-6 rounded-lg shadow-lg mb-8">
        <form onSubmit={handleSearch} className="flex items-center space-x-4 mb-4">
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Search cases..."
            className="flex-grow px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-black"
          />
          <button
  type="submit"
  className="flex items-center justify-center px-6 py-2 text-white bg-black rounded-lg hover:bg-gray-800 transition-colors duration-300"
>
  <Search className="w-4 h-4 mr-2" />
  <span>Search</span>
</button>

        </form>
       <form
  onSubmit={handleResearchFile}
  className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4"
>
  <input
    type="file"
    onChange={handleFileChange}
    accept=".pdf"
    className="flex-grow text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-black file:text-white hover:file:bg-gray-700"
  />
  <button
    type="submit"
    className="flex items-center justify-center px-6 py-2 text-white bg-black rounded-lg hover:bg-gray-800 transition-colors duration-300"
  >
    <FileUp className="w-4 h-4 mr-2" />
    <span>Research File</span>
  </button>
</form>

      </div>

     {isLoading ? (
                <LoadingIcon text='This will take 5-7 minutes.' />
            ) : (
                caseData.map((caseItem, index) => (
                    caseItem ? (
                        <CaseCard 
                            key={index} 
                            caseData={caseItem} 
                            index={index}
                            fullCaseFiles={fullCaseFile}
                            className={index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}
                        />
                    ) : (
                        <LoadingCard key={index} />
                    )
                ))
            )}
    </div>
  );

};

export default Research;
