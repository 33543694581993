import React, { FC, useEffect, useState } from "react";
import myLogic from "./logic";
import { Crossword, CluesInput } from '@jaredreisinger/react-crossword';
import axios from 'axios';
import { modelUri } from "../../constants";
import { Word, Test, GRID_HEIGHT, EMPTYCHAR } from "./logic";
import styled from 'styled-components';
import CongratulationsModal from './CongratulationsModal';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import LoadingIcon from "components/Loading/LoadingIcon";
import GiveUpPopover from "./GiveUpPopover";
const crosswordImage = "/crossword.png";


type Clue = {
    clue: string;
    answer: string;
    row: number;
    col: number;
};

interface AnswerDataObject {
  clue: string;
  answer: string;
}


type CrosswordData = {
    across: { [key: string]: Clue };
    down: { [key: string]: Clue };
};
const StyledCrossword = styled(Crossword)``;

const PageCrossword: FC<{}> = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [crosswordComplete, setCrosswordComplete] = useState(false);
    const [checkAnswers, setCheckAnswers] = useState(false);
    const [correctAnswers, setCorrectAnswers] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [dataList, setDataList] = useState<Test[]>([]);
    const [loading, setLoading] = useState(true);

    const [isGiveUpPopover, setGiveUpPopover] = useState(false)

    let grid: string[][] = [];
    for (let i = 0; i < GRID_HEIGHT; i++) {
        grid.push(new Array(GRID_HEIGHT).fill(EMPTYCHAR));
    }
    const [myData, setMyData] = useState<CrosswordData>({ across: {}, down: {} });
    const data = {
        across: {
            1: {
                clue: 'one plus one',
                answer: 'TWO',
                row: 0,
                col: 0,
            },
        },
        down: {
            2: {
                clue: 'three minus two',
                answer: 'ONE',
                row: 0,
                col: 2,
            },
            3: {
                clue: 'four times two',
                answer: 'EIGHT',
                row: 1,
                col: 0,
            }
        },
    };
    const [wordArray, setWordArray] = useState<Word[]>([]);

    const [AnswerDataArray, setAnswerDataArray] = useState<AnswerDataObject[]>([]);


  
    const closeModal = () => {
        setIsModalOpen(false);
        setCheckAnswers(false);

        setCrosswordComplete(false);
    };

    const closeGiveUpPopover = () => {
        setGiveUpPopover(false)
    }

    const getCrossword = async () => {
        let response;
        let flag = 0;
        while (true) {
            response = await axios.get(`${modelUri}/getCrossword`);
            let tempAnswer: string[] = Object.values(response.data.solution);

            flag = 0;
            for (let i = 0; i < tempAnswer.length; i++) {
                if (tempAnswer[i].length > GRID_HEIGHT) {
                    flag = 1;
                    break;
                }
            }
            if (flag == 0) {
                break;
            }
        }
        setLoading(false)
        let tempWordArray: Word[] = [];

        let clues: string[] = Object.values(response.data.hints);
        let answers: string[] = Object.values(response.data.solution);

        for (let i = 0; i < answers.length; i++) {
            tempWordArray.push({ word: answers[i].toUpperCase(), clue: clues[i], displayWord: answers[i] });
        }

        setWordArray(tempWordArray);

    }

    useEffect(() => {

        getCrossword();

    }, [])

    useEffect(() => {

        let tempDataList = [];
        [tempDataList, grid] = myLogic(wordArray);
        setDataList(tempDataList);
        let tempAnswerData = []

        let temp = { across: {}, down: {} };
        for (let i = 0; i < tempDataList.length; i++) {
            tempAnswerData.push({
                clue: tempDataList[i].clue,
                answer: tempDataList[i].answer
            })

            if (tempDataList[i].vertical == false) {
                temp.across = {
                    ...temp.across,
                    [i]: {
                        clue: tempDataList[i].clue,
                        answer: tempDataList[i].answer,
                        row: tempDataList[i].row,
                        col: tempDataList[i].col,
                    }
                }
            }
            else {
                temp.down = {
                    ...temp.down,
                    [i]: {
                        clue: tempDataList[i].clue,
                        answer: tempDataList[i].answer,
                        row: tempDataList[i].row,
                        col: tempDataList[i].col,
                    }
                }
            }
        }
        setMyData(temp);
        setAnswerDataArray(tempAnswerData)
        
    }, [wordArray])




    return (
      <div className="relative flex flex-col md:flex-row items-center justify-center min-h-screen">
            {loading ? (
                <LoadingIcon text="Generating crossword"/>
            ) : (
                    <>
                        <div className="flex justify-center w-full md:w-1/2 p-4">
                            {isGiveUpPopover && <GiveUpPopover onClose={closeGiveUpPopover} AnswerDataArray={AnswerDataArray} />}
                        <StyledCrossword
                            data={myData}
                            onCrosswordComplete={(correct: boolean) => {
                                setDisplayError(false);
                                if (wordArray.length > 0 && checkAnswers) {
                                    if (correct) {
                                        setCorrectAnswers(true);
                                        setIsModalOpen(true);
                                    } else {
                                        setCorrectAnswers(false);
                                        setIsModalOpen(true);
                                    }
                                }
                                setDisplayError(true);
                            }}
                        />
                    </div>
                  <div className="hidden md:flex justify-center w-full md:w-1/2 p-2">
                        <img src={crosswordImage} alt="Crossword" className="max-w-full rounded-full h-full object-cover" />
                   </div>

                    <div className="absolute bottom-4 md:bottom-8 mt-4">
                        <ButtonPrimary
                            children="Check"
                            className="bg-success"
                                onClick={() => {
                                    if (crosswordComplete === false) {
                                        setCorrectAnswers(false)
                                        setIsModalOpen(true)
                                    } else {
                                        setCheckAnswers(true);
                                }
                          
                            }}
                            />
                            
                            <ButtonPrimary
                            children="Give Up"
                            className="bg-red-500 mx-3 hover:bg-red-700"
                                onClick={() => {
                                    setGiveUpPopover(true)
                                    console.log(AnswerDataArray)
                                }
                          
                            }
                        />
                    </div>
                    {isModalOpen && (
                        <CongratulationsModal
                            isOpen={isModalOpen}
                            onRequestClose={closeModal}
                            setIsModalOpen={setIsModalOpen}
                            isCorrect={correctAnswers}
                        />
                    )}
                </>
            )}
        </div>

    );
}

export default PageCrossword;