import React, { FC, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { apiUri } from "../../constants";
import SubscriptionPopover from 'containers/PageSubcription/SubscriptionPopover';
import { Alert } from 'shared/Alert/Alert';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Input from 'shared/Input/Input';
import ChangePasswordFromLogin from './ChangePasswordFromLoginPopover';

interface PageLoginProps {
  className?: string;
}

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isSubscriptionPopoverOpen, setisSubscriptionPopoverOpen] = useState(false);
  const [iserror, setIsError] = useState<boolean>(false)

  const [isChangePasswordOpen, setIsChangePasswordOpen] = useState<boolean>(false)

  const handleChangePasswordPopoverOpen = () => {
    setIsChangePasswordOpen(true)
  }

  const handleChangePasswordPopoverClose = () => {
    setIsChangePasswordOpen(false)
  }
  
  const handleClose = () => {
    setisSubscriptionPopoverOpen(false);
    window.location.href = '/mootCourt'
  };

     useEffect(() => {
    if (iserror) {
      const timer = setTimeout(() => setIsError(false), 2000);
      return () => clearTimeout(timer); 
    }
  }, [iserror]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
   
    try {
      const response = await axios.post(`${apiUri}/login`, { email, password });
      //console.log('Server response:', response.data);
      if (response.data.success) {
        sessionStorage.setItem("loggedIn", "true");
        sessionStorage.setItem("subscribed", response.data.data);

        sessionStorage.setItem("email", email)

        if (response.data.data === "false") {
          // setisSubscriptionPopoverOpen(true);
           window.location.href = '/mootCourt'
        } else {
          window.location.href = '/mootCourt'
        }
        
      }
    } catch (error) {
      console.error('Error posting form data:', error);
      setIsError(true)
    }
  };

  useEffect(() => {
    if (isSubscriptionPopoverOpen) {
      <SubscriptionPopover onClose={handleClose} />
    }
  }, [isSubscriptionPopoverOpen]);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setIsEmailValid(validateEmail(emailValue));
  };

  return (
    <div className={`nc-PageLogin bg-creamyYellow min-h-screen ${className}`} data-nc-id="PageLogin">
     
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>

        {isChangePasswordOpen && <ChangePasswordFromLogin onClose={handleChangePasswordPopoverClose}/>}

        { iserror && <Alert children="Wrong Id or password" type='error' />}
        <form className="grid grid-cols-1 gap-6 max-w-md mx-auto" onSubmit={handleSubmit}>
          <label className="block">
            <span className="text-neutral-800 dark:text-neutral-200">
              Email address
            </span>
            <Input
              type="email"
              placeholder="example@example.com"
              className={`mt-1 ${isEmailValid ? 'border-green-500' : 'border-red-500'} border`}
              value={email}
              onChange={handleEmailChange}
            />
          </label>
          <label className="block">
            <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
              Password
            </span>
            <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="mt-1" />
          </label>
          <ButtonPrimary type="submit">Continue</ButtonPrimary>
          
        </form>
         <span className="block text-center text-neutral-700 dark:text-neutral-300">
          Forgot Password?{' '}
          <button className="text-blue-600" onClick={handleChangePasswordPopoverOpen}>Change Password</button>
        </span>
       <div className="relative my-6">
  <div className="bg-gradient-to-r from-blue-500 to-green-500 text-white text-center py-4 px-6 rounded-lg shadow-lg">
    <h3 className="text-lg font-semibold mb-1">Sign up today and get 10 free trial cases!</h3>
    <p className="text-sm">Start practicing your cases right away with our free trial offer.</p>
    <Link
      className="mt-3 inline-block bg-white text-blue-600 font-bold py-2 px-4 rounded-full hover:bg-gray-200 transition duration-300"
      to="/signup"
    >
      Sign Up Now
    </Link>
  </div>
</div>

       
        {isSubscriptionPopoverOpen && <SubscriptionPopover onClose={handleClose} />}
      </div>
    </div>
  );
};

export default PageLogin;


