import {
  HeartIcon,
  CubeIcon,
  HomeIcon,
  MagnifyingGlassIcon,
  UserCircleIcon,
  CurrencyDollarIcon,
  ArrowLeftIcon,
  ChevronUpIcon
} from "@heroicons/react/24/outline";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { PathName } from "routers/types";
import MenuBar from "shared/MenuBar/MenuBar";
import isInViewport from "utils/isInViewport";

let WIN_PREV_POSITION = window.pageYOffset;

interface NavItem {
  name: string;
  link?: string;
  icon?: any;
  onClick?: () => void;
  dropdownItems?: { name: string; link: string }[];
}

let NAV_ITEMS: NavItem[] = [
   {
    name: "Home",
    link: "/",
    icon: HomeIcon,
  },

    {
    name: "Products",
    icon: ChevronUpIcon,
    dropdownItems: [
      { name: "AI MootCourt", link: "/mootCourt" },
      { name: "Crossword", link: "/crossword" },
    ],
  },
  {
    name: "Playground",
    link: "/lobby",
    icon: CubeIcon,
  },
 
  {
    name: "Subscribe",
    link: "/subscription",
    icon: CurrencyDollarIcon,
  },

    {
    name: "Research(BETA)",
    link: "https://lawpotato.com/",
    icon: MagnifyingGlassIcon,
  },

];

const FooterNav = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [navItems, setNavItems] = useState<NavItem[]>(NAV_ITEMS);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const location = useLocation();
  let WIN_PREV_POSITION = 0;

  useEffect(() => {
    const loggedIn = sessionStorage.getItem('loggedIn');
    const updatedNavItems: NavItem[] = [
      ...NAV_ITEMS,
      {
        name: loggedIn ? "Log out" : "Log in",
        link: loggedIn ? "/" : "/login",
        icon: loggedIn ? ArrowLeftIcon : UserCircleIcon,
        onClick: loggedIn
          ? () => {
              sessionStorage.clear();
              window.location.href = '/login';
            }
          : undefined,
      },
    ];
    setNavItems(updatedNavItems);

    window.addEventListener("scroll", handleEvent);
    return () => {
      window.removeEventListener("scroll", handleEvent);
    };
  }, []);

  const handleEvent = () => {
    window.requestAnimationFrame(showHideHeaderMenu);
  };

  const showHideHeaderMenu = () => {
    let currentScrollPos = window.pageYOffset;
    if (!containerRef.current) return;

    if (currentScrollPos > WIN_PREV_POSITION) {
      if (
        isInViewport(containerRef.current) &&
        currentScrollPos - WIN_PREV_POSITION < 80
      ) {
        return;
      }

      containerRef.current.classList.add("FooterNav--hide");
    } else {
      if (
        !isInViewport(containerRef.current) &&
        WIN_PREV_POSITION - currentScrollPos < 80
      ) {
        return;
      }
      containerRef.current.classList.remove("FooterNav--hide");
    }

    WIN_PREV_POSITION = currentScrollPos;
  };

  const isInViewport = (element: HTMLElement) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  return (
    <div
      ref={containerRef}
      className="FooterNav p-2 bg-white dark:bg-neutral-800 fixed top-auto bottom-0 inset-x-0 z-30 border-t border-neutral-300 dark:border-neutral-700 
      transition-transform duration-300 ease-in-out"
    >
      <div className="w-full max-w-lg flex justify-around mx-auto text-sm text-center ">
        {navItems.map((item, index) => {
          const active = location.pathname === item.link;
          return item.dropdownItems ? (
            <div
              key={index}
              className={`relative flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90 ${
                active ? "text-neutral-900 dark:text-neutral-100" : ""
              }`}
            >
              <button
                className="flex items-center focus:outline-none"
                onClick={() => setDropdownVisible(!dropdownVisible)}
              >
                <item.icon className={`w-6 h-6 ${dropdownVisible ? "rotate-180" : ""}`} />
                
                <span className="text-[11px] leading-none mt-1">{item.name}</span>
              </button>
              {dropdownVisible && (
                <div className="absolute bottom-full mb-2 w-40 bg-white dark:bg-neutral-800 rounded-lg shadow-lg">
                  {item.dropdownItems.map((dropdownItem, idx) => (
                    <Link
                      key={idx}
                      to={dropdownItem.link}
                      className="block px-4 py-2 text-neutral-700 dark:text-neutral-300 hover:bg-neutral-200 dark:hover:bg-neutral-700"
                      onClick={() => setDropdownVisible(false)}
                    >
                      {dropdownItem.name}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <Link
              key={index}
              to={item.link || "#"}
              className={`flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90 ${
                active ? "text-neutral-900 dark:text-neutral-100" : ""
              }`}
              onClick={item.onClick}
            >
              {item.icon && <item.icon className={`w-6 h-6 ${active ? "text-red-600" : ""}`} />}
              <span className="text-[11px] leading-none mt-1">{item.name}</span>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default FooterNav;
