// Testimonials.tsx
import React, { useEffect, useRef } from 'react';
import TestimonialCard from './TestimonialCard'; 
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

interface Testimonial {
  id: number;
  name: string;
  role: string;
  text: string;
  imageUrl: string;
  svg: JSX.Element
}

const MaleAvatar1 = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    width="100%"
    height="100%"
    className="text-gray-600"
  >
    <circle cx="32" cy="20" r="16" fill="#6E8EAE" />
    <rect x="22" y="38" width="20" height="20" fill="#A7C6D9" />
    <path
      d="M22,38c-4,5-4,10,0,14c4,4,8,4,12,0c4-4,4-9,0-14H22z"
      fill="#BACEDC"
    />
  </svg>
);

const FemaleAvatar3 = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    width="100%"
    height="100%"
    className="text-gray-600"
  >
    <circle cx="32" cy="20" r="16" fill="#EAB8D1" />
    <rect x="22" y="38" width="20" height="20" fill="#F3D5E6" /> 
    <path
      d="M22,38c-4,5-4,10,0,14c4,4,8,4,12,0c4-4,4-9,0-14H22z"
      fill="#F9C3D7" 
    />
  </svg>
);


const FemaleAvatar1 = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    width="100%"
    height="100%"
    className="text-gray-600"
  >
    <circle cx="32" cy="20" r="16" fill="#D68C8E" />
    <rect x="22" y="38" width="20" height="20" fill="#F2A2A4" />
    <path
      d="M22,38c-4,5-4,10,0,14c4,4,8,4,12,0c4-4,4-9,0-14H22z"
      fill="#F2C2C4"
    />
  </svg>
);

const FemaleAvatar2 = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    width="100%"
    height="100%"
    className="text-gray-600"
  >
    <circle cx="32" cy="20" r="16" fill="#C7B2D9" />
    <rect x="22" y="38" width="20" height="20" fill="#E6C3D1" />
    <path
      d="M22,38c-4,5-4,10,0,14c4,4,8,4,12,0c4-4,4-9,0-14H22z"
      fill="#D2A5B0"
    />
  </svg>
);

const testimonials: Testimonial[] = [
  {
    id: 1,
    name: 'Tushar',
    role: 'Law Student',
    text: 'Helped in polishing my case analysation skills. Highly recommended!',
    imageUrl: '',
    svg: MaleAvatar1
  },
  {
    id: 2,
    name: 'Gulista Khan',
    role: 'Law Intern',
    text: 'The online mootcourt platform provided me with a realistic experience to practice my arguments and improve my courtroom skills. It\'s a game-changer!',
    imageUrl: '',
    svg: FemaleAvatar1
  },
  {
    id: 3,
    name: 'Aaraadhana Gaba',
    role: 'Law Student',
    text: 'As a law student, the AI integrated law service has been invaluable in helping me understand complex legal concepts and prepare for exams. A must-have tool!',
    imageUrl: '', 
    svg: FemaleAvatar3
  },
  {
    id: 4,
    name: 'Tanya Madhu',
    role: 'Legal Intern',
    text: 'The online mootcourt platform provided me with practical experience in presenting legal arguments and receiving feedback. It\'s a great learning tool!',
    imageUrl: '',
    svg: FemaleAvatar2
  }
];


const Testimonials: React.FC = () => {
 

  const sectionRef = useRef<HTMLDivElement | null>(null);
  const cardsRef = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const section = sectionRef.current;

    
    gsap.fromTo(section, 
      { opacity: 0, y: 100 },
      { 
        opacity: 1, 
        y: 0, 
        duration: 1, 
        scrollTrigger: {
          trigger: section,
          start: "top bottom-=100",
          end: "top center",
          scrub: true,
        },
      }
    );

  }, []);

  return (
    <div ref={sectionRef} className="home-testimonial py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center text-gray-800 mb-8">What Our Users Say</h2>
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4">
          {testimonials.map((testimonial, index) => (
            <div 
              key={testimonial.id} 

            >
              <TestimonialCard
                name={testimonial.name}
                role={testimonial.role}
                text={testimonial.text}
                imageUrl={testimonial.imageUrl}
                svg={testimonial.svg}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
