import React, { useState, useEffect } from "react";
import axios from "axios";
import { gsap } from "gsap";
import { apiUri } from "../../constants";
import Alert from "shared/Alert/Alert";

// Define TypeScript interfaces for data structure
interface TeamInAction {
  team1: string;
  team2: string;
}

interface TeamComingUp extends TeamInAction {
  StartTime: string;
}

interface TeamStatus {
  teamName: string;
}

interface ScheduleResponse {
  inAction: TeamInAction[];
  comingUp: TeamComingUp[];
  eliminated: TeamStatus[];
  resting: TeamStatus[];
}

const SchedulePage: React.FC = () => {
  const [teamsInAction, setTeamsInAction] = useState<TeamInAction[]>([]);
  const [teamsComingUp, setTeamsComingUp] = useState<TeamComingUp[]>([]);
  const [eliminatedTeams, setEliminatedTeams] = useState<TeamStatus[]>([]);
  const [restingTeams, setRestingTeams] = useState<TeamStatus[]>([]);


    useEffect(() => {
    
            const fetch = async () => {
                try {
                
                    const response = await axios.post(`${apiUri}/fullSchedule`, { id: sessionStorage.getItem('FetchMootCourtId') })
            
                    //   const response = {
                    //     "inAction": [{ "team1": "Team A", "team2": "Team B" },{ "team1": "Team A", "team2": "Team B" },{ "team1": "Team A", "team2": "Team B" },{ "team1": "Team A", "team2": "Team B" },{ "team1": "Team A", "team2": "Team B" },{ "team1": "Team A", "team2": "Team B" },{ "team1": "Team A", "team2": "Team B" }],
                    //     "ScheduledTeams": [{ "team1": "Team C", "team2": "Team D", "StartTime": "12:00 PM" },{ "team1": "Team C", "team2": "Team D", "StartTime": "12:00 PM" },{ "team1": "Team C", "team2": "Team D", "StartTime": "12:00 PM" }],
                    //     "eliminatedTeams": [{ "teamName": "Team E" },{ "teamName": "Team E" },{ "teamName": "Team E" }],
                    //     "idealTeams": [{ "teamName": "Team F" },{ "teamName": "Team E" },{ "teamName": "Team E" }]
                    //  }
            
                    const { inAction, ScheduledTeams, eliminatedTeams, idealTeams } = response.data.data
                    setTeamsInAction(inAction);
                    setTeamsComingUp(ScheduledTeams);
                    setEliminatedTeams(eliminatedTeams);
                    setRestingTeams(idealTeams);
                }
                catch (error) {
                    //do nothing 
                }
            }
        
        fetch()
    
        }, []);
 
    
  useEffect(() => {
      gsap.from(".team-card", { opacity: 0, y: 50, duration: 0.5, stagger: 0.2 });
  }, [teamsInAction, teamsComingUp, eliminatedTeams, restingTeams]);

  return (
    <div className="container mx-auto p-8">
      <h1 className="text-4xl font-bold w-1/4 text-center mb-10  px-4 py-2 bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 text-white font-bold rounded-full shadow-lg transform rotate-[-2deg] transition-transform duration-300 hover:scale-105 hover:rotate-2">Moot Court Schedule</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        
        <CategoryCard className= 'bg-green-200' title="Teams In Action" teams={teamsInAction} type="action" />

        <CategoryCard title="Coming Up" teams={teamsComingUp} type="upcoming" />

        <CategoryCard title="Eliminated Teams" teams={eliminatedTeams} type="eliminated" />

        <CategoryCard className= 'bg-yellow-200' title="Resting Teams" teams={restingTeams} type="resting" />
      </div>
    </div>
  );
};

// Define props for CategoryCard
interface CategoryCardProps {
  title: string;
  teams: TeamInAction[] | TeamComingUp[] | TeamStatus[];
    type: "action" | "upcoming" | "eliminated" | "resting";
    className?: string
}

// Component for each category
const CategoryCard: React.FC<CategoryCardProps> = ({ className, title, teams, type }) => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  // Filter teams based on the search term
  const filteredTeams = teams.filter((team) => {
    if (type === "action" || type === "upcoming") {
      const matchTeam1 = (team as TeamInAction).team1.toLowerCase().includes(searchTerm.toLowerCase());
      const matchTeam2 = (team as TeamInAction).team2.toLowerCase().includes(searchTerm.toLowerCase());
      return matchTeam1 || matchTeam2;
    } else if (type === "resting" || type === "eliminated") {
      return (team as TeamStatus).teamName.toLowerCase().includes(searchTerm.toLowerCase());
    }
    return false;
  });

   const renderTeams = () => {
    if (type === "action" || type === "upcoming") {
      return (teams as (TeamInAction | TeamComingUp)[]).map((team, index) => (
          <div key={index} className={`team-card ${className} bg-white p-4 shadow-lg rounded-lg`}>
          <div className="text-xl font-semibold mb-2">
            {team.team1} vs {team.team2}
          </div>
          {type === "upcoming" && (
            <div className="text-sm text-gray-500">
              Time: {(team as TeamComingUp).StartTime}
            </div>
          )}
        </div>
      ));
    } else if (type === "resting" || type === "eliminated") {
      return (teams as TeamStatus[]).map((team, index) => (
        <div
          key={index}
          className={`team-card ${
            type === "eliminated" ? "bg-red-200" : "bg-yellow-200"
          } p-4 shadow-lg rounded-lg`}
        >
          <div className="text-xl font-semibold mb-2">{team.teamName}</div>
        </div>
      ));
    }
  };


  return (
<div className="category-card p-6 bg-gradient-to-r from-blue-300 via-purple-400 to-blue-500 rounded-xl shadow-lg transform hover:scale-105 transition-transform duration-300">
  <h2 className="text-3xl font-extrabold text-center mb-4 text-white drop-shadow-lg">{title}</h2>
  {/* Search Bar */}
  <input
    type="text"
    placeholder="Search by team name..."
    className="w-full p-3 mb-4 border border-pink-200 rounded-md bg-white text-gray-700 focus:outline-none focus:ring-2 focus:ring-pink-300"
    value={searchTerm}
    onChange={(e) => setSearchTerm(e.target.value)}
  />
  {/* Team List */}
  <div className="max-h-64 overflow-y-auto space-y-4 bg-white bg-opacity-20 rounded-lg p-4 shadow-inner">
    {filteredTeams.length > 0 ? renderTeams() : <p className="text-center text-white">No teams available</p>}
  </div>
</div>

  );
};

export default SchedulePage;
