import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';

const HeadingSvg: React.FC = () => {
  const textRef = useRef<HTMLHeadingElement | null>(null);
  const lineRef = useRef<SVGPathElement | null>(null);
  const taglineRef = useRef<HTMLHeadingElement | null>(null);

  useEffect(() => {
    const timeline = gsap.timeline({ repeat: -1, repeatDelay: 1 });

    timeline
      .fromTo(
        lineRef.current,
        { strokeDashoffset: 300 },
        { strokeDashoffset: 0, duration: 2, ease: 'power1.inOut' }
      )
      .to(lineRef.current, { strokeDashoffset: 300, duration: 2, ease: 'power1.inOut' });

    gsap.set(taglineRef.current, { y: 20 });

    gsap.to(taglineRef.current, {
      y: 0,
      stagger: 0.5,
      delay: 0.2,
      duration: 0.5,
      yoyo: true,
      repeat: -1,
      repeatDelay: 3,
    });

    return () => {
      timeline.kill();
    };
  }, []);

  return (
    <div className="relative flex flex-col items-center justify-center w-full">
      <h1
        ref={textRef}
        className="relative z-10 text-5xl md:text-8xl font-extrabold text-black"
      >
        AI MOOTCOURT
      </h1>
      <h2
        ref={taglineRef}
        className="relative italic text-lg md:text-2xl z-10 mt-4 font-semibold text-gray-600"
      >
        The Court Before The Court
      </h2>
      <svg
        viewBox="0 0 800 200"
        className="absolute top-0 left-0 w-full h-full"
        preserveAspectRatio="none"
      >
        <path
          ref={lineRef}
          d="M 0 120 C 150 50, 350 170, 500 80 C 650 10, 750 160, 800 100"
          fill="none"
          stroke="rgba(4, 172, 255, 1)"
          strokeWidth="10"
          markerEnd="url(#arrowhead)"
          strokeDasharray="300"
          strokeDashoffset="300"
        />
      </svg>
    </div>
  );
};

export default HeadingSvg;
