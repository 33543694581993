// src/components/SideSelectPopover.tsx
import React, { useState, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import axios from 'axios';
import { apiUri } from '../../constants';

interface SideSelectPopoverProps {

    onClose: () => void;
    caseId: string;

};

const SideSelectPopover: React.FC<SideSelectPopoverProps> = ({ onClose, caseId }) => {


    const handleJoinProsecution = async () => {

    
        sessionStorage.setItem("caseId", caseId)
        
        sessionStorage.setItem("userSide", "Prosecutor");
            window.location.href = "/pageCourt"
        onClose()
  
    };

    const handleJoinDefence = async () => {
        
      
        sessionStorage.setItem("caseId", caseId)
        sessionStorage.setItem("userSide", "Defendant");
          window.location.href = "/pageCourt"
        onClose()
     
    }

    const handleClosePopover = () => {
        onClose()
    }

    return (
        <Popover>
            <Transition
                show={true}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
            >
                <Popover.Panel static className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-creamyYellow dark:bg-gray-800 rounded-lg p-4 shadow-lg max-w-md w-full">
                        <h2 className="text-xl font-bold mb-2 text-neutral-900 dark:text-neutral-100">Pick a side</h2>

                        <div className="text-center">

                            <button
                                type="submit"
                                onClick={handleJoinDefence}
                                className="bg-teal-500 mt-4 text-white px-4 py-2 w-full rounded-md hover:bg-teal-700 mr-2"
                            >
                                Act as Defence
                            </button>

                            <button
                                type="submit"
                                onClick={handleJoinProsecution}
                                className="bg-cyan-500 mt-4 text-white px-4 py-2 w-full rounded-md hover:bg-cyan-700 mr-2"
                            >
                                Act as Prosecution
                            </button>
                        </div>
                        <div className='text-center justify-center'>
                            <button
                                type="submit"
                                onClick={handleClosePopover}
                                className="bg-red-500 mt-4 text-white px-4 py-2 w-1/2 rounded-md hover:bg-red-700 mr-2"
                            >
                                Close
                            </button>
                        </div>
                    </div>

                </Popover.Panel>
            </Transition>
        </Popover>
    );
};

export default SideSelectPopover;
