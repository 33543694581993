import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { apiUri } from '../../constants';
import Alert from 'shared/Alert/Alert';

type RatingCategory = 'Overall experience' | 'UI experience' | 'Ease of use' | 'AI responses';

interface FeedbackState {
  ratings: {
    [key in RatingCategory]: number;
  };
  detailedFeedback: string;
}

const initialFeedbackState: FeedbackState = {
  ratings: {
    'Overall experience': 0,
    'UI experience': 0,
    'Ease of use': 0,
    'AI responses': 0,
  },
  detailedFeedback: '',
};

const StarRating: React.FC<{ rating: number; onRate: (rating: number) => void }> = ({ rating, onRate }) => {
  const [hoverRating, setHoverRating] = useState<number>(0);


  return ( 
    <div className="flex">
      {[1, 2, 3, 4, 5].map((star) => (
        <svg
          key={star}
          className={`w-6 h-6 cursor-pointer hover:text-yellow-400 ${
            star <= (hoverRating || rating) ? 'text-yellow-400' : 'text-gray-300'
          }`}
          fill="currentColor"
          viewBox="0 0 24 24"
          onClick={() => onRate(star)}
          onMouseEnter={() => setHoverRating(star)}
          onMouseLeave={() => setHoverRating(0)}
        >
          <path d="M12 .587l3.668 7.568L24 9.432l-6 5.853L19.335 24 12 19.897 4.665 24 6 15.285l-6-5.853 8.332-1.277L12 .587z" />
        </svg>
      ))}
    </div>
  );
};

const FeedbackComponent: React.FC = () => {
  const [feedback, setFeedback] = useState<FeedbackState>(initialFeedbackState);
    const [isError, setIsError] = useState<boolean>(false);

  const handleRatingChange = (category: RatingCategory, rating: number) => {
    setFeedback((prevFeedback) => ({
      ...prevFeedback,
      ratings: {
        ...prevFeedback.ratings,
        [category]: rating,
      },
    }));
  };

  const handleDetailedFeedbackChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFeedback((prevFeedback) => ({
      ...prevFeedback,
      detailedFeedback: event.target.value,
    }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
 
    try {
        const response = await axios.post(`${apiUri}/addFeedback`, {
        email: sessionStorage.getItem("email"),
        overall: feedback.ratings['Overall experience'],
        easeOfUse: feedback.ratings['Ease of use'],
        ai: feedback.ratings['AI responses'],
        ui: feedback.ratings['UI experience'],
          detailedFeedback: feedback.detailedFeedback
        })
      
      if (response.data.success) {
        window.location.href = '/mootcourt'
      }
    } catch (error) {
      setIsError(true)
    } 
    
  };

    useEffect(() => {
    if (isError) {
      const timer = setTimeout(() => setIsError(false), 2000);
      return () => clearTimeout(timer); 
    }
  }, [isError]);

  return (
    <div className='mx-auto mt-6 mb-6' >

      <span className='text-center'>
        <h1 className=' text-4xl font-bold p-5' >Thank you for using our service.</h1>
        <h3 className='mb-4'>Kindly provide a feedback and help us improve.</h3>
      </span>

      {isError && <Alert children="Unable to add feedback" type='error'></Alert>}

    <form onSubmit={handleSubmit} className="max-w-lg mx-auto container p-6 bg-creamyYellow border border-black border-3 shadow-lg rounded-md">
      
      {(['Overall experience', 'UI experience', 'Ease of use', 'AI responses'] as RatingCategory[]).map((category) => (
        <div key={category} className="mb-4">
          <label className="block text-gray-700 font-semibold mb-2">{category}</label>
          <StarRating
            rating={feedback.ratings[category]}
            onRate={(rating) => handleRatingChange(category, rating)}
          />
        </div>
      ))}
      <div className="mb-4">
        <label className="block text-gray-700 font-semibold mb-2">Detailed Feedback</label>
        <textarea
          className="w-full p-2 border rounded-md"
          rows={4}
          value={feedback.detailedFeedback}
          onChange={handleDetailedFeedbackChange}
        />
      </div>
      <button
        type="submit"
        className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-200"
      >
        Submit Feedback
      </button>
      </form>
         </div>
  );
};

export default FeedbackComponent;
