// ContactSection.tsx
import React from 'react';

const ContactSection: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-auto py-16 px-8 shadow-lg">
      <div className="max-w-4xl w-full">
        <div className="text-center mb-12">
          <span className="text-sm text-gray-500">Our team is available to assist you with any inquiries.</span>
          <div className="mt-6">
            <h2 className="text-3xl font-bold">Contact Us</h2>
            <p className="mt-4 text-lg text-peach">Have a question or need support? Reach out to us!</p>
          </div>
        </div>
        <div className="grid md:grid-cols-3 gap-8">
         
          <div className="flex flex-col bg-peach items-center  p-6 rounded-lg shadow-md hover:shadow-xl transition-transform transform hover:rotate-3">
            <svg
              viewBox="0 0 1024 1024"
              className="w-12 h-12 text-purple-600 mb-4"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
            </svg>
            <div className="text-center">
              <h3 className="text-xl font-semibold text-white">Email</h3>
              <p className="mt-2 text-lg text-white">Feel free to contact us via email!</p>
              <span className="block mt-2 text-sm text-white">adyityasingh@gmail.com</span>
            </div>
          </div>

          
          <div className="flex flex-col bg-peach items-center  p-6 rounded-lg shadow-md hover:shadow-xl transition-transform transform hover:rotate-3">
            <svg
              viewBox="0 0 1024 1024"
              className="w-12 h-12 text-purple-600 mb-4"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M282 460q96 186 282 282l94-94q20-20 44-10 72 24 152 24 18 0 30 12t12 30v150q0 18-12 30t-30 12q-300 0-513-213t-213-513q0-18 12-30t30-12h150q18 0 30 12t12 30q0 80 24 152 8 26-10 44z"></path>
            </svg>
            <div className="text-center">
              <h3 className="text-xl font-semibold text-white">Phone</h3>
              <p className="mt-2 text-lg text-white">We look forward to hearing from you!</p>
              <span className="block mt-2 text-sm text-white">+91 7017158278/8383990480 </span>
            </div>
          </div>

         
          <div className="flex flex-col bg-peach items-center  p-6 rounded-lg shadow-md hover:shadow-xl transition-transform transform hover:rotate-3">
            <svg
              viewBox="0 0 1024 1024"
              className="w-12 h-12 text-purple-600 mb-4"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M512 0c-176.732 0-320 143.268-320 320 0 320 320 704 320 704s320-384 320-704c0-176.732-143.27-320-320-320zM512 512c-106.040 0-192-85.96-192-192s85.96-192 192-192 192 85.96 192 192-85.96 192-192 192z"></path>
            </svg>
            <div className="text-center">
              <h3 className="text-xl font-semibold text-white">Office</h3>
              <p className="mt-2 text-lg text-white">Pay us a visit!</p>
              <span className="block mt-2 text-sm text-white">SPD Plaza, Koramangala Industrial Layout, 5th Block, Koramangala, Bengaluru, Karnataka 560034</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
