// src/components/PeerPopover.tsx
import React, { useState, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import axios from 'axios';
import { apiUri } from '../../constants';
import io, { Socket } from 'socket.io-client';
import CopyButton from 'components/CopyButton/Copy';

const PeerPopover: React.FC<{ onClose: () => void }> = ({ onClose }) => {
    const [sessionId, setsessionId] = useState("")

    const handleJoinSession = async () => {
        try {

            sessionStorage.setItem("sessionId", sessionId)
            onClose();
        } catch (error) {
            console.error('Failed to create session', error);
        }
    };

    const handleCreateSession = async () => {
        try {
            const response = await axios.get(`${apiUri}/create-session`)
            setsessionId(response.data.session);
            sessionStorage.setItem("sessionId", response.data.sessionId)           
            // onClose()
        } catch (error) {
            //console.log(error)
        }
    }


    const handlesessionIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setsessionId(e.target.value)
    }

    return (
        <Popover>
            <Transition
                show={true}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
            >
                <Popover.Panel static className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-creamyYellow dark:bg-gray-800 rounded-lg p-4 shadow-lg max-w-md w-full">
                        <h2 className="text-xl font-bold mb-2 text-neutral-900 dark:text-neutral-100">Invite Friend</h2>
                        <form>
                            <input
                                type="sessionId"
                                className="w-full h-10 p-2 border rounded-md dark:bg-gray-700 dark:text-neutral-200"
                                placeholder="Enter sessionId ID"
                                value={sessionId}
                                onChange={handlesessionIdChange}
                                required
                            />
                            <div className="mt-4 flex justify-end">
                                <button
                                    type="submit"
                                    onClick={handleJoinSession}
                                    className="bg-blue-500 mb-4 text-white px-4 py-2 rounded-md hover:bg-blue-700 mr-2"
                                >
                                    Join session
                                </button>
                            </div>
                        </form>

                        <hr />

                        <div className="text-center">
                            <p className="text-xl">OR</p>
                            {sessionId != '' && <CopyButton text={sessionStorage.getItem("sessionId")}
                                onClose={onClose}
                            ></CopyButton>}
                            <button
                                type="submit"
                                onClick={handleCreateSession}
                                className="bg-green-500 mt-4 text-white px-4 py-2 w-full rounded-md hover:bg-green-700 mr-2"
                            >
                                Create Session
                            </button>
                        </div>
                    </div>

                </Popover.Panel>
            </Transition>
        </Popover>
    );
};

export default PeerPopover;
