import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import ListingStayPage from "containers/ListingStayPage/ListingStayPage";
import ListingStayMapPage from "containers/ListingStayPage/ListingStayMapPage";
import ListingExperiencesPage from "containers/ListingExperiencesPage/ListingExperiencesPage";
import ListingExperiencesMapPage from "containers/ListingExperiencesPage/ListingExperiencesMapPage";
import ListingCarPage from "containers/ListingCarPage/ListingCarPage";
import ListingCarMapPage from "containers/ListingCarPage/ListingCarMapPage";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import PayPage from "containers/PayPage/PayPage";
import AuthorPage from "containers/AuthorPage/AuthorPage";
import AccountPage from "containers/AccountPage/AccountPage";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountBilling from "containers/AccountPage/AccountBilling";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import PageAddListing1 from "containers/PageAddListing1/PageAddListing1";
import PageAddListing2 from "containers/PageAddListing1/PageAddListing2";
import PageAddListing3 from "containers/PageAddListing1/PageAddListing3";
import PageAddListing4 from "containers/PageAddListing1/PageAddListing4";
import PageAddListing5 from "containers/PageAddListing1/PageAddListing5";
import PageAddListing6 from "containers/PageAddListing1/PageAddListing6";
import PageAddListing7 from "containers/PageAddListing1/PageAddListing7";
import PageAddListing8 from "containers/PageAddListing1/PageAddListing8";
import PageAddListing9 from "containers/PageAddListing1/PageAddListing9";
import PageAddListing10 from "containers/PageAddListing1/PageAddListing10";
import PageHome2 from "containers/PageHome/PageHome2";
import ListingRealEstateMapPage from "containers/ListingRealEstatePage/ListingRealEstateMapPage";
import ListingRealEstatePage from "containers/ListingRealEstatePage/ListingRealEstatePage";
import SiteHeader from "containers/SiteHeader";
import ListingFlightsPage from "containers/ListingFlightsPage/ListingFlightsPage";
import FooterNav from "components/FooterNav";
import useWindowSize from "hooks/useWindowResize";
import PageHome3 from "containers/PageHome/PageHome3";
import ListingStayDetailPage from "containers/ListingDetailPage/listing-stay-detail/ListingStayDetailPage";
import ListingCarDetailPage from "containers/ListingDetailPage/listing-car-detail/ListingCarDetailPage";
import ListingExperiencesDetailPage from "containers/ListingDetailPage/listing-experiences-detail/ListingExperiencesDetailPage";
import MootCourtPage from "containers/mootcourt/mootCourtPage";
import PageCourt from "containers/PageCourt/PageCourt";
import ListCaseDetails from "containers/CaseDetails/CaseDetail";
import GetAnalytics from "containers/PageAnalytics/PageAnalytics";
import PaySuccess from "containers/PageSubcription/PaymentSuccess";
import FeedbackComponent from "containers/Feedback/Feedback";
import OurVision from "containers/Vision/Vision";
import PageP2P from "containers/PageCourt/PageP2P";
import Crossword from "containers/Crossword/Crossword";
import P2PAnalytics from "containers/PeerToPeerDecision/P2PAnalysation";
import { P2PAnalyticsProps } from "./types";
import Search from "containers/AdvocateModule/Search";
import Lobby from "containers/HostMootcourt/Lobby";
import PageCollegeMootcourt from "containers/HostMootcourt/CollegeMootcourtPage";
import ContactSection from "components/ContactUs/Contact";
import GetLobbyPage from "containers/HostMootcourt/CreateLobbyPage";
import SelectOurCasePage from "containers/HostMootcourt/SelectOurCases";
import UserRegisterMoot from "containers/HostMootcourt/UserRegisterMoot";
import MootRegistrationForm from "containers/HostMootcourt/RegisterYourMoot";
import RegisterSuccess from "containers/HostMootcourt/RegisterSuccess";
import VideoConference from "containers/VideoConference/VideoConference";
import Profile from "containers/HostMootcourt/Profile";
import GetFinalMootAnalysis from "containers/HostMootcourt/FinalMootAnalysis";
import SchedulePage from "containers/PageHome/Schedule";
import MootCourtAnalysationWrapper from "containers/PeerToPeerDecision/MootCourtAnalyzation";
import DiscountedPageSubscription from "containers/PageHome/DiscountedPage";
import TermsandCond from "containers/PageLogin/T&C";
import PrivacyPolicy from "containers/PageLogin/PrivacyPolicy";

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/#", exact: true, component: PageHome },
  { path: "/home-1-header-2", exact: true, component: PageHome },
  { path: "/home-2", component: PageHome2 },
  { path: "/home-3", component: PageHome3 },
  //
  { path: "/listing-stay", component: ListingStayPage },
  { path: "/listing-stay-map", component: ListingStayMapPage },
  { path: "/listing-stay-detail", component: ListingStayDetailPage },
  //
  {
    path: "/listing-experiences",
    component: ListingExperiencesPage,
  },
  {
    path: "/listing-experiences-map",
    component: ListingExperiencesMapPage,
  },
  {
    path: "/listing-experiences-detail",
    component: ListingExperiencesDetailPage,
  },
  //
  { path: "/listing-car", component: ListingCarPage },
  { path: "/listing-car-map", component: ListingCarMapPage },
  { path: "/listing-car-detail", component: ListingCarDetailPage },
  //
  { path: "/listing-real-estate-map", component: ListingRealEstateMapPage },
  { path: "/listing-real-estate", component: ListingRealEstatePage },
  //
  { path: "/listing-flights", component: ListingFlightsPage },
  //
  { path: "/checkout", component: CheckOutPage },
  { path: "/pay-done", component: PayPage },
  //
  { path: "/author", component: AuthorPage },
  { path: "/account", component: AccountPage },
  { path: "/account-password", component: AccountPass },
  { path: "/account-savelists", component: AccountSavelists },
  { path: "/account-billing", component: AccountBilling },
  //
  { path: "/blog", component: BlogPage },
  { path: "/blog-single", component: BlogSingle },
  //
  { path: "/add-listing-1", component: PageAddListing1 },
  { path: "/add-listing-2", component: PageAddListing2 },
  { path: "/add-listing-3", component: PageAddListing3 },
  { path: "/add-listing-4", component: PageAddListing4 },
  { path: "/add-listing-5", component: PageAddListing5 },
  { path: "/add-listing-6", component: PageAddListing6 },
  { path: "/add-listing-7", component: PageAddListing7 },
  { path: "/add-listing-8", component: PageAddListing8 },
  { path: "/add-listing-9", component: PageAddListing9 },
  { path: "/add-listing-10", component: PageAddListing10 },
  //
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/subscription", component: PageSubcription },
  { path: "/mootCourt", component: MootCourtPage },
  { path: "/pageCourt", component: PageCourt },
  { path: "/caseDetails", component: ListCaseDetails },

  { path: "/analysis", component: GetAnalytics },

  { path: "/paySuccess", component: PaySuccess },

  { path: "/feedback", component: FeedbackComponent },

  { path: "/vision", component: OurVision },

  { path: "/mooting", component: PageP2P },

  { path: "/crossword", component: Crossword },

  { path: "/p2pAnalytics", component: P2PAnalytics },

  { path: "/advocateSearch", component: Search },

  { path: "/lobby", component: Lobby },

  { path: "/collegeMootcourt", component: PageCollegeMootcourt },

  { path: "/contactus", component: ContactSection },

  { path: "/selectOurCase", component: SelectOurCasePage },

  { path: "/userRegisterMoot", component: UserRegisterMoot },

  { path: "/mootInfoRegistration", component: MootRegistrationForm },

  { path: "/registerSuccess", component: RegisterSuccess },

  { path: "/MootAnalysis", component: GetFinalMootAnalysis },

  { path: "/profile", component: Profile },

  { path: "/videoConference", component: VideoConference },

  { path: "/fullSchedule", component: SchedulePage },
  
  { path: "/mootCourtAnalysis", component: MootCourtAnalysationWrapper }, 

  { path: "/couponCheckout", component: DiscountedPageSubscription },
  
  { path: "/TermsAndConditions", component: TermsandCond },
  
  {path: "/privacyPolicy", component: PrivacyPolicy}

];

const MyRoutes = () => {
  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }
  const [shouldRenderHeader, setShouldRenderHeader] = useState<boolean>(false);

  useEffect(() => {
    const currentPath = window.location.pathname;
    const isHeaderAllowedPath = !(
     
      currentPath === '/pageCourt' ||
      currentPath === '/pagecourt' ||
      currentPath === '/mooting' ||
      currentPath === '/collegeMootcourt' ||
      currentPath === '/collegemootcourt' ||
      currentPath === '/selectOurCase' ||
      currentPath === '/userRegisterMoot' 
    );

    let delay = 0;
    // if (currentPath === '/') {
    //   delay = 5000;
    // } else if (isHeaderAllowedPath) {
    //   delay = 0;
    // }

    const timer = setTimeout(() => {
      if (isHeaderAllowedPath) {
        setShouldRenderHeader(true);
      }
    }, delay);

    // Cleanup the timer if the component unmounts
    return () => clearTimeout(timer);
  }, [window.location.pathname]);

  return (
    <BrowserRouter>
      <ScrollToTop />
      {shouldRenderHeader && <SiteHeader />}

      <Routes>
        {pages.map(({ component, path }) => {
          const Component = component;
          return <Route key={path} element={<Component />} path={path} />;
        })}
        <Route element={<Page404 />} />
      </Routes>

      {(window.innerWidth < 1024 && shouldRenderHeader) && <FooterNav />} 
      <Footer />
    </BrowserRouter>
  );
};

export default MyRoutes;
