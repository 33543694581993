// src/components/NotesPopover.tsx
import React, { useState, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';

const NotesPopover: React.FC<{ onClose: () => void }> = ({ onClose }) => {
    const [notes, setNotes] = useState(() => {
        const savedNotes = sessionStorage.getItem('userNotes');
        return savedNotes ? JSON.parse(savedNotes) : '';
    });

    useEffect(() => {
        sessionStorage.setItem('userNotes', JSON.stringify(notes));
    }, [notes]);

    const handleNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNotes(event.target.value);
    };

    return (
        <Popover>
         <Transition
            show={true}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Popover.Panel static className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                <div className="bg-white dark:bg-gray-800 rounded-lg p-4 shadow-lg max-w-md w-full">
                    <h2 className="text-xl font-bold mb-2 text-neutral-900 dark:text-neutral-100">Notes</h2>
                    <textarea
                        className="w-full h-40 p-2 border rounded-md dark:bg-gray-700 dark:text-neutral-200"
                        value={notes}
                        onChange={handleNotesChange}
                    ></textarea>
                    <div className="mt-4 flex justify-end">
                        <button
                            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                            onClick={onClose}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </Popover.Panel>
        </Transition>
        </Popover>
    );
};

export default NotesPopover;
