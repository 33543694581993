import Heading from "components/Heading/Heading";
import React, { FC, useEffect, useRef } from "react";
import VectorImg from "images/VectorHIW.svg";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export interface SectionHowItWorkProps {
  className?: string;
  data?: {
    id: number;
    title: string;
    desc: string;
    img: string;
    imgDark?: string;
  }[];
}

const datat: SectionHowItWorkProps["data"] = [
  {
    id: 1,
    img: "/howitworks1.jpeg",
    title: "Its all AI",
    desc: "The AI acts as the prosecutor, the judge and the witnesses.",
  },
  {
    id: 2,
    img: "/howitworks2.jpeg",
    title: "Logic based",
    desc: "The AI acts on logic, with each of the argument analyzed on the current laws",
  },
  {
    id: 3,
    img: "/howitworks3.jpeg",
    title: "The decision",
    desc: "The AI judge then evaluates the arguments. One with stronger logic wins.",
  },
];

const SectionHowItWork: FC<SectionHowItWorkProps> = ({
  className = "",
  data = datat,
}) => {
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const section = sectionRef.current;

    
    gsap.fromTo(
      section,
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        scrollTrigger: {
          trigger: section,
          start: "top bottom-=100",
          end: "top center",
          scrub: true,
        },
      }
    );

    itemRefs.current.forEach((item, index) => {
      gsap.fromTo(
        item,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          delay: index * 0.1, 
          scrollTrigger: {
            trigger: item,
            start: "top bottom-=50",
            end: "top center",
            scrub: true,
          },
        }
      );
    });
  }, []);

  return (
    <div
      ref={sectionRef}
      className={`nc-SectionHowItWork ${className}`}
      data-nc-id="SectionHowItWork"
    >
      <Heading isCenter desc="AI and magic">
        How it works
      </Heading>
      <div className="mt-20 relative grid md:grid-cols-3 gap-20">
        <img
          className="hidden md:block absolute inset-x-0 top-10"
          src={VectorImg}
          alt=""
        />
        {data.map((item, index) => (
          <div
            key={item.id}
            ref={(el) => (itemRefs.current[index] = el)} 
            className="relative flex flex-col items-center max-w-xs mx-auto"
          >
            {item.imgDark ? (
              <>
                <img
                  className="dark:hidden block mb-8 max-w-[200px] mx-auto rounded-full"
                  src={item.img}
                />
                <img
                  className="hidden dark:block mb-8 max-w-[200px] mx-auto rounded-full"
                  src={item.imgDark}
                />
              </>
            ) : (
              <img
                className="mb-8 max-w-[200px] mx-auto rounded-full"
                src={item.img}
              />
            )}
            <div className="text-center mt-auto">
              <h3 className="text-xl font-semibold">{item.title}</h3>
              <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                {item.desc}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionHowItWork;
