import React, { FC, useEffect, useState } from "react";
import Heading from "components/Heading/Heading";
import { DEMO_POSTS } from "data/posts";
import { PostPosterType } from "data/types";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import WidgetTags from "./WidgetTags";
import WidgetCategories from "./WidgetCategories";
import WidgetPosts from "./WidgetPosts";
import Card3 from "./Card3";
import axios from "axios";
import LoadingIcon from "components/Loading/LoadingIcon";
import { apiUri } from "../../constants";

// THIS IS DEMO FOR MAIN DEMO
// OTHER DEMO WILL PASS PROPS
// const postsDemo: PostPosterType[] = DEMO_POSTS.filter((_, i) => i > 7 && i < 14);
//
export interface SectionLatestPostsProps {
  posts?: PostPosterType[];
  className?: string;
  postCardName?: "card3";
}

const SectionLatestPostsRenderer: FC<SectionLatestPostsProps> = ({
  posts = [],
  postCardName = "card3",
  className = "",
}) => {
  const renderCard = (post: PostPosterType) => {
    switch (postCardName) {
      case "card3":
        return <Card3 key={post.id} className="" post={post} />;

      default:
        return null;
    }
  };

  return (
    <div className={`nc-SectionLatestPosts relative ${className}`}>
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 xl:pr-14">
          <Heading>Latest Blogs 🎈</Heading>
          <div className="grid gap-6 md:gap-8 grid-cols-1 bg-gradient-to-r from-blue-50 to-blue-200 p-6 rounded-lg shadow-lg transition-all duration-300 ease-in-out">
  {posts.map((post) => renderCard(post))}
</div>

          <div className="flex flex-col mt-12 md:mt-20 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
            {/* <Pagination /> */}
            {/* <ButtonPrimary>Show me more</ButtonPrimary> */}
          </div>
        </div>
        <div className="w-full space-y-7 mt-24 lg:mt-0 lg:w-2/5 lg:pl-10 xl:pl-0 xl:w-1/3 ">
          <WidgetTags />
          {/* <WidgetCategories />
          <WidgetPosts /> */}
        </div>
      </div>
    </div>
  );
};


function SectionLatestPosts() {
  const [details, setDetails] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response = await axios.post(`${apiUri}/fetchAllBlogs`);
        setDetails(response.data.data);
      } catch (error) {
        console.error('Error fetching case details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, []);

  if (loading) {
    return <LoadingIcon text="Fetching all Blogs"/>;
  } else if (details) {
    return <SectionLatestPostsRenderer posts = {details} />;
  } else {
    return <p>Error loading blog.</p>;
  }
}

export default SectionLatestPosts;
