import React from 'react';

interface TestimonialCardProps {
  name: string;
  role: string;
  text: string;
  imageUrl: string;
  svg: JSX.Element;
}

const TestimonialCard: React.FC<TestimonialCardProps> = ({ name, role, text, imageUrl, svg }) => {
  return (
    <div className="relative overflow-hidden rounded-lg shadow-lg transition-transform duration-300 transform bg-white hover:scale-105 hover:shadow-xl">
      <div className="absolute inset-0 bg-gradient-to-b from-blue-500 to-blue-200 opacity-20 rounded-lg"></div>
      <div className="relative p-6 flex flex-col items-center">
        {svg}
        <div className="text-center">
          <strong className="text-xl font-semibold text-gray-800">{name}</strong>
          <span className="block text-gray-500 text-sm">{role}</span>
          <p className="mt-4 text-gray-700 text-center">{text}</p>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
