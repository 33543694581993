import { Popover, Transition } from '@headlessui/react';
import { X, Maximize2, Minimize2 } from 'lucide-react';
import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';

const HowItWorksPopover: React.FC<{ onClose: () => void }> = ({ onClose }) => {
    const [isFullScreen, setIsFullScreen] = useState(false);

    const toggleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
    };

    useEffect(() => {
        gsap.from(".step-card", {
            opacity: 0,
            y: 50,
            duration: 0.6,
            stagger: 0.3,
            ease: "power2.out",
        });
    }, []);

    return (
        <Popover>
            <Transition
                show={true}
                enter="transition duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition duration-150 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <Popover.Panel 
                    static 
                    className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
                >
                    <div className={`bg-creamyYellow dark:bg-gray-800 rounded-lg shadow-xl overflow-scroll ${
                        isFullScreen ? 'w-full h-full' : 'w-11/12 h-5/6 max-w-4xl max-h-[90vh]'
                    }`}>
                        <div className="flex justify-between items-center p-4 bg-black">
                            <h2 className="text-xl font-bold text-white">How it Works</h2>
                            <div className="flex text-white space-x-2">
                                <button
                                    onClick={toggleFullScreen}
                                    aria-label={isFullScreen ? "Exit full screen" : "Enter full screen"}
                                >
                                    {isFullScreen ? <Minimize2 className="h-5 w-5" /> : <Maximize2 className="h-5 w-5" />}
                                </button>
                                <button
                                    onClick={onClose}
                                    aria-label="Close"
                                >
                                    <X className="h-5 w-5" />
                                </button>
                            </div>
                        </div>
                        
                        <div className="bg-creamyYellow p-6 rounded-lg mb-8">
                            <h1 className="text-3xl font-bold mb-6 text-gray-800 text-center">
                                Prepare Your Legal Case
                            </h1>

                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                                <div className="step-card bg-blue-100 p-6 rounded-lg shadow-md">
                                    <h2 className="text-xl font-semibold text-gray-700 mb-4">
                                        1. Select a Case Category
                                    </h2>
                                    <p className="text-gray-600">
                                        Explore past cases across various categories like Criminal, Civil, Commercial, and more. Get an in-depth understanding of the facts and legal sections involved.
                                    </p>
                                </div>

                                <div className="step-card bg-blue-200 p-6 rounded-lg shadow-md">
                                    <h2 className="text-xl font-semibold text-gray-700 mb-4">
                                        2. Analyze Case Details
                                    </h2>
                                    <p className="text-gray-600">
                                        Study the case details thoroughly, including the rulings and involved sections. Formulate a solid legal strategy based on these insights.
                                    </p>
                                </div>

                                <div className="step-card bg-blue-100 p-6 rounded-lg shadow-md">
                                    <h2 className="text-xl font-semibold text-gray-700 mb-4">
                                        3. Choose Your Role
                                    </h2>
                                    <p className="text-gray-600">
                                        Decide if you want to act as the prosecutor or defense counsel. Your strategy will vary depending on the side you take.
                                    </p>
                                </div>

                                <div className="step-card bg-blue-200 p-6 rounded-lg shadow-md">
                                    <h2 className="text-xl font-semibold text-gray-700 mb-4">
                                        4. Prepare for Trial
                                    </h2>
                                    <p className="text-gray-600">
                                        Once ready, you can choose to battle it out with AI ("Fight with AI"), go against a peer ("Friend"), or form teams ("Teams") to test your skills in group competition.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    );
};

export default HowItWorksPopover;
