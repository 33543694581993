import React, { FC } from "react";
import rightImgPng from "images/our-features.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import StackedCards from "components/StepWorker/Stepworker";
import StoryStep from "containers/PageHome/StoryStep";

export interface SectionOurFeaturesProps {
  className?: string;
  rightImg?: string;
  type?: "type1" | "type2";
}

const SectionOurFeatures: FC<SectionOurFeaturesProps> = ({
  className = "lg:py-14",
  rightImg = rightImgPng,
  type = "type1",
}) => {
  return (
    <div
      className={`nc-SectionOurFeatures relative flex flex-col items-center ${
        type === "type1" ? "lg:flex-row" : "lg:flex-row-reverse"
      } ${className}`}
      data-nc-id="SectionOurFeatures"
    >
      <div className="flex-grow">
        <StoryStep/>
      </div>
      <div
        className={`max-w-2xl flex-shrink-0 mt-10 lg:mt-0 lg:w-2/5 ${
          type === "type1" ? "lg:pl-16" : "lg:pr-16"
        }`}
      >
<div className="max-w-4xl mx-auto p-6">
  <span className="uppercase text-sm text-gray-400 tracking-widest">
    BENEFITS
  </span>
  <h2 className="font-semibold text-4xl mt-5 text-gray-800">
    Train Your Communication and Analytical Skills
  </h2>

  <ul className="space-y-10 mt-16">
    <li className="space-y-4 border-l-4 border-blue-500 pl-4">
      <Badge name="🗣️ Speaking" />
      <span className="block text-xl font-semibold text-gray-800">
        Communication Skills
      </span>
      <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
        Defending in court by speaking allows users to practice their speaking skills.
      </span>
    </li>

    <li className="space-y-4 border-l-4 border-green-500 pl-4">
      <Badge color="green" name="📚 Exposure" />
      <span className="block text-xl font-semibold text-gray-800">
        Real Life Cases
      </span>
      <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
        The cases are derived from real cases and judgments.
      </span>
    </li>

    <li className="space-y-4 border-l-4 border-red-500 pl-4">
      <Badge color="red" name="📦 Availability" />
      <span className="block text-xl font-semibold text-gray-800">
        All You Need
      </span>
      <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
        All the case laws and resources are just a touch away.
      </span>
    </li>
  </ul>
</div>

      </div>
    </div>
  );
};

export default SectionOurFeatures;
