import TeamSelectPopover from "./TeamSelectPopover";
import { useState, useEffect } from "react";
import CreateLobbyPage from "./CreateLobbyPage";
import JoinLobbyPopover from "./JoinLobbyPopover";
import axios from "axios";
import { apiUri } from "../../constants";
import Badge from "shared/Badge/Badge";
import AllLobbiesPopover from "./AllLobbiesPopover";
import AllUpcomingCourtsPopover from "./AllMootcourtsPopover";
import LoadingIcon from "components/Loading/LoadingIcon";

interface Lobby {
    _id: string;
    courtName: string;
}

interface MootCourt {
    _id: string,
    caseTitle: string,
    collegeName: string,
    time: string,
    dateOfStart: string,
    dateOfEnd: string,
    teamSize: string,
}

const Lobby: React.FC<{}> = () => {
    const [isMakeLobbyOpen, setisMakeLobbyOpen] = useState(false);
    const [isJoinLobbyOpen, setisJoinLobbyOpen] = useState(false);
    const [openTeamSelect, setOpenTeamSelect] = useState(false);
    const [enteredLobby, setEnteredLobby] = useState('');

    const [lobbies, setLobbies] = useState<Lobby[]>([]);
    const [mootCourts, setMootCourts] = useState<MootCourt[]>([]);
    const [loading, setLoading] = useState(true);

    const [lobbyName, setLobbyName] = useState("")
    const [allCourtsOpen, setAllCourtsOpen] = useState(false)
    const [allLobbiesOpen, setAllLobbiesOpen] = useState(false)

    useEffect(() => {
        const fetchLobbiesAndMootCourts = async () => {
            try {
                const [lobbiesResponse, mootCourtsResponse] = await Promise.all([
                    axios.get(`${apiUri}/fetchAllLobbies`),
                    axios.get(`${apiUri}/fetchAllScheduled`),
                ]);

                //console.log(lobbiesResponse.data.data)

                setLobbies(lobbiesResponse.data.data);
                setMootCourts(mootCourtsResponse.data.data);
            } catch (error) {
                console.error("Error fetching data", error);
            } finally {
                setLoading(false);
            }
        };

        fetchLobbiesAndMootCourts();
    }, []);

    const handleJoinLobbyClose = () => {
        setisMakeLobbyOpen(false);
        setisJoinLobbyOpen(false);
        setLobbyName("")
    };

    const handleCourtClose = () => {
        setAllCourtsOpen(false)
    }

    const handleViewAllCourts = () => {
        setAllCourtsOpen(true)
    }

    const handleUserRegister = (x: MootCourt) => {
        if (!sessionStorage.getItem("email")) {
            window.location.href = "/login"
        } else {
            //console.log(x)
            sessionStorage.setItem("FetchMootCourtId", x._id)
            window.location.href = "/userRegisterMoot"
        }
    }

    const handleJoinLobbyOpen = () => {
        setisJoinLobbyOpen(true);
    };

    const handleAllLobbiesClose = () => {
        setAllLobbiesOpen(false)
    }

    const handleJoinLobby = (name: string) => {
          if (!sessionStorage.getItem("email")) {
            window.location.href = "/login"
          }
        
        setLobbyName(name)
        handleJoinLobbyOpen();
    };

    const handleTeamSelectClose = () => {
        setOpenTeamSelect(false);
    };

    const handleCreateLobby = async () => {
        setisMakeLobbyOpen(true);
        //console.log("Creating new lobby");
    };

    const handleViewAllLobbies = () => {
        setAllLobbiesOpen(true)
    };

    return (
        <div className="min-h-screen flex flex-col lg:flex-row bg-creamyYellow p-6">
            {(isMakeLobbyOpen) &&
                <CreateLobbyPage
                    onClose={handleJoinLobbyClose}
                    setOpenTeamSelect={setOpenTeamSelect}
                    setEnteredLobby={setEnteredLobby}
                ></CreateLobbyPage>
            }

            {isJoinLobbyOpen && <JoinLobbyPopover name={lobbyName} onClose={handleJoinLobbyClose} setEnteredLobby={setEnteredLobby} setOpenTeamSelect={setOpenTeamSelect}></JoinLobbyPopover>}

            {openTeamSelect && <TeamSelectPopover onClose={handleTeamSelectClose} hostedCourtName={enteredLobby}></TeamSelectPopover>}

            {allLobbiesOpen && <AllLobbiesPopover lobbies={lobbies} onClose={handleAllLobbiesClose} />}

            {allCourtsOpen && <AllUpcomingCourtsPopover UpcomingCourts={mootCourts} onClose={handleCourtClose} />}

            {!isMakeLobbyOpen && !isJoinLobbyOpen && (
                <>
                    <div className="lg:w-1/4 p-4 bg-peach rounded-lg shadow-md mb-6 lg:mb-0">
                        <h1 className="text-3xl font-bold text-white mb-8 animate-fade-in">
                            Welcome to the Moot Court Platform
                        </h1>
                        <p className="text-blakc mb-6">
                            This platform empowers you to create personal lobbies and engage in moot court cases with your team. Colleges can add custom cases to foster competitive learning environments.
                        </p>
                        <p className="text-black mb-6">
                            Whether you're honing your legal skills or preparing for an upcoming competition, our platform provides the tools and community to support your journey.
                        </p>
                        <div className="text-center mt-10">
                            <h2 className="text-2xl font-semibold text-gray-800 mb-4">How It Works</h2>
                            <p className="text-white">
                                1. Create or join a lobby for practice or competition.<br />
                                2. Select your team and the case you want to argue.<br />
                                3. Participate in real-time moot courts.<br />
                                4. Explore and register for upcoming moot court events.
                            </p>
                        </div>
                    </div>

                    <div className="lg:w-2/4 p-4 bg-creamyYellow rounded-lg shadow-xl border border-black mx-3 mb-6 lg:mb-0">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-4 text-center">Upcoming Moot Courts</h2>
                        {loading ? (
                            <LoadingIcon text="Loading MootCourts" />
                            
                        ) : (
                            <div>
                                <ul className="divide-y divide-gray-200">
                                    {mootCourts.length > 0 ? (
                                        mootCourts.slice(0, 5).map((court, index) => (
                                            <li key={court._id} className="py-4 flex justify-between items-center">
                                                <div>
                                                    <span className="text-lg font-medium text-black">
                                                        {index + 1}. {court.caseTitle}
                                                    </span>
                                                    <p className="text-sm text-black">
                                                        Host: {court.collegeName} | Date: {court.dateOfStart} | Time: {court.time}
                                                    </p>
                                                </div>
                                                <button
                                                    onClick={() => handleUserRegister(mootCourts[index])}
                                                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
                                                >
                                                    Register
                                                </button>
                                            </li>
                                        ))
                                    ) : (
                                        <div className="text-center text-black py-10">
                                            No upcoming moot courts available.
                                        </div>
                                    )}
                                        </ul>
                                {mootCourts.length > 5 && (
                                    <button
                                        onClick={handleViewAllCourts}
                                        className="w-full py-3 mb-4 bg-gray-500 text-white rounded hover:bg-gray-600 transition duration-300"
                                    >
                                        View All
                                    </button>
                                )}
                            </div>


                        )}
                    </div>

                    <div className="lg:w-1/4 p-4 bg-peach rounded-lg shadow-md">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Active Lobbies</h2>
                        <Badge name="Live" color="pink" className="animate-bounce" ></Badge>
                        {loading ? (
                              <LoadingIcon text="Loading Lobbies" />
                        ) : (
                            <>
                                <div className="mb-6">
                                    {lobbies.length > 0 ? (
                                        <ul>
                                            {lobbies.slice(0, 4).map((lobby) => (
                                                <li
                                                    key={lobby.courtName}
                                                    className="flex justify-between items-center py-3 px-4 bg-creamyYellow hover:bg-yellow-400 rounded mb-2 transition duration-300 ease-in-out"
                                                >
                                                    <div>
                                                        <h2 className="text-lg font-medium text-black">
                                                            {lobby.courtName}
                                                        </h2>
                                                        <p className="text-sm text-black">
                                                            Password Protected
                                                        </p>
                                                    </div>
                                                    <button
                                                        onClick={() => handleJoinLobby(lobby.courtName)}
                                                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
                                                    >
                                                        Join
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <div className="text-center text-white py-10">
                                            No active lobbies available.
                                        </div>
                                    )}
                                </div>
                                {lobbies.length > 4 && (
                                    <button
                                        onClick={handleViewAllLobbies}
                                        className="w-full py-3 mb-4 bg-gray-500 text-white rounded hover:bg-gray-600 transition duration-300"
                                    >
                                        View All Lobbies
                                    </button>
                                )}
                                <button
                                    onClick={handleCreateLobby}
                                    className="w-full py-3 bg-green-500 text-white rounded hover:bg-green-600 transition duration-300"
                                >
                                    Create a New Lobby
                                </button>
                                <button
                                    onClick={() => handleJoinLobby("")}
                                    className="w-full py-3 mt-4 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
                                >
                                    Join Lobby
                                </button>
                            </>
                        )}
                    </div>
                </>)}
        </div>
    );
};

export default Lobby;
