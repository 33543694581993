import React, { FC, useEffect, useState } from "react";
import { CaseDataType } from "data/types";
import Pagination from "shared/Pagination/Pagination";
import CaseCard from "components/CaseCard/CaseCard";
import axios from "axios";
import { apiUri } from "../../constants";
import LoadingIcon from "components/Loading/LoadingIcon";
import HowItWorksPopover from "containers/CaseDetails/HowItWorksPopover";
import SurpriseMe from "./SurpriseMe";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: CaseDataType[];
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
}) => {
  const [cases, setCases] = useState<CaseDataType[]>([]);
  const [filteredCases, setFilteredCases] = useState<CaseDataType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [caseType, setCaseType] = useState<string>("All");

  const casesPerPage = 15;
  const [currentPage, setCurrentPage] = useState<number>(1);

  const subscribed = sessionStorage.getItem("subscribed")

  const [freeCases, setFreeCases] = useState(0)

  const [displayWorkingPopover, setdisplayeWorkingPopover] = useState(true)

   const fetchRandomCase = () => {
    if (cases.length === 0) return;
    const randomIndex = Math.floor(Math.random() * cases.length);
    const randomCase = cases[randomIndex];
    setSearchTerm(randomCase.title);
    
   };
  
  useEffect(() => {

    const freeTrial = async () => {
    const email = sessionStorage.getItem("email")

    if (email) {
      try {
        const response = await axios.post(`${apiUri}/getFreeTrial`, { email: email });
                  console.log(response.data.data)
        if (response.data.data.freeCases > 0) {
          
          setFreeCases(response.data.data.freeCases)
          sessionStorage.setItem("freeCases", response.data.data.freeCases.toString())
        
        } else {
         
          setFreeCases(0)
           sessionStorage.setItem("freeCases", "0")
        }
      } catch (error) {
        console.error('Error fetching trail remaining details:', error);
      }
    }
    }
    freeTrial()
      }, [])

  useEffect(() => {
    const fetchCases = async () => {
      try {
        const response = await axios.get(`${apiUri}/fetchAllCases`);
        const shuffledCases = shuffleCases(response.data.data);
        setCases(shuffledCases);
        setFilteredCases(shuffledCases);
      } catch (error) {
        setError("Error fetching case data");
      } finally {
        setLoading(false);
      }
    };

    fetchCases();
  }, []);

  const closePopover = () => {
    setdisplayeWorkingPopover(false)
  }

  const shuffleCases = (array: CaseDataType[]): CaseDataType[] => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  useEffect(() => {
    const filterCases = () => {
      let tempCases = cases;

      if (caseType !== "All") {
        tempCases = tempCases.filter(
          (caseItem) => caseItem.type.toLowerCase() === caseType.toLowerCase()
        );
      }

      if (searchTerm) {
        tempCases = tempCases.filter((caseItem) =>
          caseItem.title.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      setFilteredCases(tempCases);
    };

    filterCases();
    setCurrentPage(1); 
  }, [searchTerm, caseType, cases]);

  const indexOfLastCase = currentPage * casesPerPage;
  const indexOfFirstCase = indexOfLastCase - casesPerPage;
  const currentCases = filteredCases.slice(indexOfFirstCase, indexOfLastCase);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  if (loading) {
    return <LoadingIcon />;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      {displayWorkingPopover && <HowItWorksPopover onClose={closePopover}/>}
      <div className="flex items-center justify-between mt-4 mb-4">
        
  <h2 className="text-3xl font-semibold relative">
    Practice Cases
    <span className="text-sm font-medium text-gray-600 animate-pulse ml-4">
      — Adding cases every week —
    </span>
        </h2>
        
        {subscribed != null && subscribed === "false" && <div className="flex items-center">
          <span className="text-white text-xl font-bold bg-gradient-to-r from-red-500 to-yellow-500 py-1 px-3 rounded-full shadow-lg animate-pulse">
            Free Cases Remaining: <span className="text-3xl font-extrabold ml-2">{freeCases}</span>
          </span>
        </div>}

        {subscribed != null && subscribed === "true" && <div className="flex items-center">
          <span className="text-white text-xl font-bold bg-gradient-to-r from-red-500 to-yellow-500 py-1 px-3 rounded-full shadow-lg animate-pulse">
            PREMIUM
          </span>
        </div>}
</div>




    
      <div className="max-w-2xl mx-auto mb-6 px-4 p-5 shadow-xl rounded-full">
        
  <div className="flex w-full rounded-full">
    <select
      className="flex-shrink-0 w-32 mr-2 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-l-full hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
      value={caseType}
      onChange={(e) => setCaseType(e.target.value)}
    >
      <option value="All">Categories</option>
      <option value="Criminal">Criminal</option>
      <option value="Civil">Civil</option>
      <option value="Commercial">Commercial</option>
    </select>

    <div className="relative flex-grow">
      <input
        type="search"
        id="search-dropdown"
        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-r-full border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-l-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
        placeholder="Search cases by title..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        required
      />
      <button
        className="absolute top-0 right-0 p-2.5 text-sm font-medium h-full text-white bg-blue-700 rounded-r-full border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      >
        <svg
          className="w-4 h-4"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
          />
        </svg>
        <span className="sr-only">Search</span>
            </button>
            
            
    </div>
        </div>
        
        </div>


    <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          <SurpriseMe onSurprise={fetchRandomCase} />
        {currentCases.map((caseItem) => (
          <CaseCard key={caseItem.id} data={caseItem} />
        ))}
      </div>
    </div>

      <div className="flex mt-16 justify-center items-center">
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(filteredCases.length / casesPerPage)}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
