import React, { FC, useRef, useEffect, useState } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import Button from 'shared/Button/Button';
import { init, animateCamera, toggleCharacterVisibility } from './threeUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faRobot, faMicrophone, faBrain, faNotesMedical } from '@fortawesome/free-solid-svg-icons';
import NotesPopover from 'components/CaseTools/NotesPopover';
import axios from 'axios';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import IpcBotPopover from 'components/CaseTools/IPCbot/IpcBotPopover';
import MessageBox from './messageBox';
import { Message } from './types';
import CaseDetailsPopover from 'components/CaseTools/CaseDetailsPopover';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { apiUri, modelUri } from '../../constants';
import { Alert } from 'shared/Alert/Alert';
import TutorialPopover from 'containers/Tutorial/Tutorial';
import LoadingButton from 'components/Loading/LoadingButton';
import Sidebar from 'components/Sidebar/Sidebar';

const PageCourt: FC<{}> = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const sceneRef = useRef<THREE.Scene>();
  const cameraRef = useRef<THREE.PerspectiveCamera>();
  const rendererRef = useRef<THREE.WebGLRenderer>();
  const controlsRef = useRef<OrbitControls>();
  const textSpriteRef = useRef<THREE.Sprite>();
  const textSpriteRefLawyer2 = useRef<THREE.Sprite>();
  const witnessCharacterRef = useRef<THREE.Object3D | null>(null);
  const statsRef = useRef<Stats>();
  const [lawyer2Text, setLawyer2Text] = useState<string>('');
  const conversationLogRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [fullUserConversation, setFullUserConversation] = useState<string>('');
  const [isTutorialOpen, setisTutorialOpen] = useState(true)

  const [isNotesPopoverOpen, setIsNotesPopoverOpen] = useState(false);
  const [isCaseDetailsPopoverOpen, setisCaseDetailsPopoverOpen] = useState(false)
  const [isBotPopoverOpen, setIsBotPopoverOpen] = useState(false);
  const [input, setInput] = useState<string>('');
  const [isListening, setIsListening] = useState<boolean>(false);
  const { transcript, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();
  const [witnessInStand, setWitnessInStand] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const [analysisReady, setAnalysisReady] = useState(false)

  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const [sideMenuIsExpand, setSideMenuIsExpand] = useState(false);
  const [isExpand, setIsExpand] = useState(false);

  useEffect(() => {
    init(sceneRef, cameraRef, canvasRef, rendererRef, controlsRef, textSpriteRef, textSpriteRefLawyer2, statsRef, witnessCharacterRef);
  }, []);

  useEffect(() => {
    if (conversationLogRef.current) {
      conversationLogRef.current.scrollTop = conversationLogRef.current.scrollHeight;
    }
  }, [lawyer2Text]);

  useEffect(() => {
    //console.log("VALUE OF WITNESS IS:" + witnessInStand)
    toggleCharacterVisibility(witnessInStand, witnessCharacterRef, sceneRef, cameraRef, rendererRef);
  }, [witnessInStand]);

  // useEffect(() => {
  //   updateTextSprite(dynamicText, sceneRef, textSpriteRef, { x: -3.55, y: 1.5, z: 2 });
  // }, [dynamicText]);

  // useEffect(() => {
  //   updateTextSprite(lawyer2Text, sceneRef, textSpriteRefLawyer2, { x: -3, y: 1.5, z: -1.5 });
  // }, [lawyer2Text]);

  const caseActive: string = sessionStorage.getItem("caseId") || ""

  let iPrompt: string = ""
  let iWitnessPrompt: string = ""

  const getPrompts = async () => {
    try {
      const response = await axios.post(`${apiUri}/getPrompts`, {
        caseId: caseActive
      });  // Make sure to include this option to send cookies with requests
      //console.log(response.data.data);
      iPrompt = response.data.data.initialPrompt
      iWitnessPrompt = response.data.data.witnessPrompt
    } catch (error) {
      console.error("Error setting prompts:", error);
    }

  }


  const getStoredMessages = (): Message[] => {
    const storedMessages = sessionStorage.getItem('messages');
    return storedMessages ? JSON.parse(storedMessages) : [];
  };

  useEffect(() => {
    setMessages(getStoredMessages());
  }, []);

  useEffect(() => {
    if (transcript && !isListening) {
      setInput(transcript);
      // setFullUserConversation((prevConversation) => prevConversation + ' ' + transcript);
      //console.log(fullUserConversation);
      resetTranscript();
    }
  }, [transcript, isListening, fullUserConversation, resetTranscript]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    if (messages.length > 0) {


      if (messages[messages.length - 1].role === "judge") {
        animateCamera(cameraRef, controlsRef, new THREE.Vector3(-2, 1, -0.3), new THREE.Vector3(-4, 1, -0.3), 3000)
      }
      else if (messages[messages.length - 1].role === "lawyer") {
        animateCamera(cameraRef, controlsRef, new THREE.Vector3(-1, 2, -3), new THREE.Vector3(-3.55, 0, -1), 3000)
      }
      else {
        animateCamera(cameraRef, controlsRef, new THREE.Vector3(-1, 2, 3), new THREE.Vector3(-3.55, 0, 1), 3000)
      }
    }
  }, [messages]);

  useEffect(() => {
    sessionStorage.setItem('messages', JSON.stringify(messages));
  }, [messages]);

  const startVoiceRecognition = () => {
    if (browserSupportsSpeechRecognition) {
      SpeechRecognition.startListening({ continuous: true });
      setIsListening(true);
    } else {
      console.error('Browser does not support speech recognition.');
    }
  };

  const handleVoiceInput = () => {
    startVoiceRecognition();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value);
    resetTranscript();
  };

  const handleMicEnd = () => {
    SpeechRecognition.stopListening();
    setIsListening(false);
  };

  useEffect(() => {
    sessionStorage.setItem("fullUserConversation", fullUserConversation)
  }, [fullUserConversation]);

  useEffect(() => {
    if (input === "i rest my case") {
      handleRestMyCase();
    }
  }, [input]);

  const handleSendMessage = async () => {
    setIsProcessing(true)
    await getPrompts();
    if (input.trim() !== '') {
      const newMessage: Message = { role: 'User', text: input };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setFullUserConversation((prevConversation) => `${prevConversation} ${input}`);
      setInput('');
      resetTranscript();

      try {

        const response = await axios.post(`${modelUri}/model`, { message: input, initial_prompt: iPrompt, witness_prompt: iWitnessPrompt, userSide: sessionStorage.getItem('userSide') });
        const role = response.data.speaker;
        const botMessage: Message = { role, text: response.data.msg };

        setMessages((prevMessages) => [...prevMessages, botMessage]);
        setLawyer2Text(response.data.msg);
      } catch (error) {
        console.error('There was an error making the POST request!', error);
      }
      setInput('');
      resetTranscript();
      setIsProcessing(false)

    }
  };

  const handleButtonClick = () => {
    setIsNotesPopoverOpen(true);
  };

  const handleClosePopover = () => {
    setIsNotesPopoverOpen(false);
  };

  const handleBotClosePopover = () => {
    setIsBotPopoverOpen(false)
  }

  const handleBotClick = () => {
    setIsBotPopoverOpen(true)
  }

  const handleTutorialPopover = () => {
    setisTutorialOpen(false)
  }

  const handleDetailClick = () => {
    setisCaseDetailsPopoverOpen(true)
  }

  const handleCLoseDetailsPopover = () => {
    setisCaseDetailsPopoverOpen(false)
  }

  const handleQuestionWitness = () => {
    setInput("i want to question the witness")
    handleSendMessage()
  }


  const handleRestMyCase = async () => {
    await handleSendMessage()
    setAnalysisReady(true)
  }

  const handleAnalytics = () => {
    window.location.href = "/analysis"
  }

  const handleExitCourt = async () => {
    <Alert children="Ending court session and directing to analytics" type="success" />
    setTimeout(function () {
      window.location.href = "/feedback";
    }, 2000);
  }

  return (
    <div className="relative bg-creamyYellow min-h-screen md:flex">
      {/* sidemenu */}
      <Sidebar isExpand={isExpand} setIsExpand={setIsExpand} setExpand={setSideMenuIsExpand} messages={messages} conversationLogRef={conversationLogRef} />
      {/* content */}
      <div
        className={`flex-1 min-h-screen mx-0 bg-slate-100 transition-all duration-300 ease-in-out ${sideMenuIsExpand ? "md:ml-72" : "md:ml-20"
          }`}
      >
        <div className="flex flex-col">
          {isTutorialOpen && <TutorialPopover onClose={handleTutorialPopover} />}
          <div className=" flex flex-col lg:flex-row">
            <div className="flex flex-col h-1/2 items-center lg:h-full w-full lg:w-3/4">
              {lawyer2Text &&
                <MessageBox characterName={messages[messages.length - 1].role === "judge" ? "Judge" : "Prosecutor"} message={lawyer2Text}></MessageBox>}


              <canvas ref={canvasRef} className="w-full lg:w-1/2 h-1/2"></canvas>

            </div>


            <div className="flex flex-col w-full lg:w-1/4 z-10 bg-peach border border-black p-4">
              <textarea className='mb-2 w-full border border-black justify-center px-4 h-[10rem] rounded-md' name="" id="" placeholder="Enter your text" value={input} onChange={(event) => { handleInputChange(event) }}></textarea>
              
              {isProcessing && <LoadingButton />}
              
              <Button
                className="text-neutral-700 dark:text-neutral-200  border border-black  hover:bg-green-400  bg-green-300 mb-2 transition duration-300 ease-in-out transform hover:scale-105"
                onClick={handleSendMessage}
              >
                Send
              </Button>

              
              
              <Button
                className="text-neutral-700  border border-black  hover:bg-gray-200 dark:text-neutral-200 bg-creamyYellow mb-2 transition duration-300 ease-in-out transform hover:scale-105 flex items-center"
                onClick={handleVoiceInput}
                disabled={isListening}
              >
                <FontAwesomeIcon icon={faMicrophone} className="mr-2" />
                Microphone
              </Button>
              {isListening && (
                <div className="flex items-center border border-black justify-center p-2 border border-black rounded bg-white">
                  <span className="animate-pulse">Listening...</span>
                  <Button
                    className="text-neutral-700   hover:bg-gray-200 dark:text-neutral-200 bg-red-400 mb-4 flex items-center"
                    onClick={handleMicEnd}
                  >
                    <FontAwesomeIcon icon={faMicrophone} className="mr-2" />
                    Stop
                  </Button>
                </div>
              )}


              <Button
                className="text-neutral-700 border border-black  hover:bg-gray-200 dark:text-neutral-200 bg-creamyYellow mb-2 flex items-center transition duration-300 ease-in-out transform hover:scale-105"
                onClick={handleDetailClick}>
                <FontAwesomeIcon icon={faBook} className="mr-2" />
                Case Details
              </Button>
              {isCaseDetailsPopoverOpen && <CaseDetailsPopover onClose={handleCLoseDetailsPopover} />}
              <Button
                className="text-neutral-700 dark:text-neutral-200  border border-black hover:bg-gray-200 bg-creamyYellow mb-2 flex items-center transition duration-300 ease-in-out transform hover:scale-105"
                onClick={handleBotClick}
              >
                <FontAwesomeIcon icon={faRobot} className="mr-2" />
                Avyukta Bot
              </Button>
              {isBotPopoverOpen && <IpcBotPopover onClose={handleBotClosePopover} />}
              <Button className="text-neutral-700 border border-black hover:bg-gray-200 dark:text-neutral-200 bg-creamyYellow mb-2 transition duration-300 ease-in-out transform hover:scale-105 flex items-center" onClick={() => { setIsExpand(!isExpand) }}>
                <FontAwesomeIcon icon={faBrain} className="mr-2" />
                Logs
              </Button>
              
              <Button
                className="text-neutral-700  border border-black hover:bg-gray-200  dark:text-neutral-200 transition duration-300 ease-in-out transform hover:scale-105 bg-creamyYellow mb-2 flex items-center"
                onClick={handleButtonClick}
              >
                <FontAwesomeIcon icon={faNotesMedical} className="mr-2" />
                Notes
              </Button>
              {isNotesPopoverOpen && <NotesPopover onClose={handleClosePopover} />}

               <ButtonPrimary
              onClick={handleAnalytics}
              className="bg-red-500 border border-black text-white rounded-lg shadow-lg px-6 py-2 mb-4 transition duration-300 ease-in-out transform hover:scale-105 hover:bg-red-700"
            >
              Get Judgement
            </ButtonPrimary>
            {/* <ButtonPrimary
              onClick={handleExitCourt}
              className="bg-black text-creamyYellow rounded-lg shadow-lg px-6 py-2 transition duration-300 ease-in-out transform hover:scale-105 hover:bg-gray-800"
            >
              Exit session
            </ButtonPrimary> */}

            </div >
          </div>

        </div>
      </div>
    </div>
  );
};

export default PageCourt;
