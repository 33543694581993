import React from "react";
import NavigationItem from "./NavigationItem";
import { NAVIGATION_DEMO } from "data/navigation";

function Navigation() {
  return (
    <ul className="nc-Navigation flex lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
      {NAVIGATION_DEMO.map((item) => {
        if (item.name === "Crossword") {
          return (<><NavigationItem key={item.id} menuItem={item} />
            <span className="absolute top-0 right-0 text-xs animate-bounce bg-yellow-500 text-black rounded-full px-2 py-1">Coming Soon</span></>)
        }
        else {
          return <NavigationItem key={item.id} menuItem={item} />;
        }

      })}
    </ul>
  );
}

export default Navigation;
