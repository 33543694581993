import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import { apiUri, modelUri } from '../../constants';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Alert from 'shared/Alert/Alert';
import LoadingIcon from 'components/Loading/LoadingIcon';

interface PageAnalyticsProps {
  idealComparison: string
  score: string
  givenConversation: string
  idealConversation: string
  VocabScore: string
  SenScore: string
  ThemeScore: string
  OverallCohScore: string
  Decision: string
}


const PageAnalytics: React.FC<PageAnalyticsProps> = ({
  idealComparison,
  score,
  VocabScore,
  SenScore,
  ThemeScore,
  OverallCohScore,
  givenConversation,
  idealConversation,
  Decision
}) => {
  const getScoreColor = () => {
    if (score >= "80") return 'text-green-600';
    if (score >= "40") return 'text-yellow-600';
    return 'text-red-600';
  };


  const handleExit = () => {
    sessionStorage.removeItem("fullUserConversation")
    window.location.href = '/feedback'
  }

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0 },
  };

  return (
    <div className='text-center container justify-center'>

      <motion.div
        className="max-w-6xl mx-auto p-6 bg-creamyYellow border-black border-2 shadow-lg rounded-lg border 0"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
        transition={{ duration: 0.5, ease: 'easeOut' }}
      >
         <div className="mb-4">
          <h3 className="text-4xl font-semibold text-gray-800">Decision</h3>
     
            <motion.div
              className="mt-2 text-gray-600 text-xl"
              variants={itemVariants}
              transition={{ delay: 0.2 }}
            >
              <p>{Decision}</p>
            </motion.div>
    
        </div>

        <div className="mb-4">
          <h3 className="text-4xl font-semibold text-gray-800">Ideal Comparison</h3>
          <div className="flex flex-col md:flex-row justify-between gap-6 mx-auto">
            <motion.div
              className="mt-2 text-gray-600 w-full h-64 p-4 overflow-scroll md:w-1/2"
              variants={itemVariants}
              transition={{ delay: 0.2 }}
            >
              <h4 className="text-2xl font-semibold text-gray-800">Your Argument</h4>
              <p className='text-lg'>{givenConversation}</p>
            </motion.div>
            <motion.div
              className="mt-2 text-gray-600 w-full h-64 p-4 overflow-scroll md:w-1/2"
              variants={itemVariants}
              transition={{ delay: 0.3 }}
            >
              <h4 className="text-2xl font-semibold text-gray-800">Ideal Argument</h4>
              <p className='text-lg'>{idealConversation}</p>
            </motion.div>
          </div>

          
        </div>
       <div className="flex flex-col items-center justify-center mb-4 text-center">
  <h3 className="text-4xl">Comment on similarity</h3>

  <motion.div
    className="mt-2 text-black w-full md:w-1/2"
    variants={itemVariants}
    transition={{ delay: 0.3 }}
  >
    <p>{idealComparison}</p>
  </motion.div>

  <h3 className="text-4xl font-semibold text-gray-800 mt-4">Score</h3>

  <motion.div
    className={`mt-2 text-6xl font-bold ${getScoreColor()}`}
    variants={itemVariants}
    transition={{ delay: 0.4 }}
  >
    {score}
  </motion.div>
</div>

        <div className=''>
          <h3 className="text-3xl font-semibold text-gray-800">Score Breakdown</h3>
          <motion.ul
            className="mt-2 list-disc list-inside text-2xl text-gray-600"
            variants={itemVariants}
            initial="hidden"
            animate="visible"
            transition={{ delay: 0.5 }}
          >
            <li>Vocabulory: {VocabScore}</li>
            <li>Sentense Structuring: {SenScore}</li>
            <li>Theme: {ThemeScore}</li>
            <li>Coherence: {OverallCohScore}</li>
          </motion.ul>
        </div>
      </motion.div>
      <div className='p-6 text-2xl'>
        <button className="rounded-xl text-white text-bold shadow-lg text-center text-2xl p-6 justify-center bg-red-500 hover:bg-red-700" onClick={handleExit}> Exit </button>
      </div>
    </div>
  );
};


function GetAnalytics() {

  const userConversation = sessionStorage.getItem("fullUserConversation")
  const userSide = sessionStorage.getItem('userSide')
  const [caseDetails, setcaseDetails] = useState<string>("")
  const [details, setDetails] = useState<PageAnalyticsProps | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchIdealConversation = async () => {
      const caseId = sessionStorage.getItem('caseId');
      if (!caseId) return;

      try {
        const response = await axios.post(`${apiUri}/fetchArgument`, { caseId });
        if (response.data.success) {
          setcaseDetails(response.data.data.initialPrompt);
        }
      } catch (error) {
        //console.log(error);
      }
    };

    fetchIdealConversation();
  }, []);

  useEffect(() => {
    if (!caseDetails) return;

    const fetchDetails = async () => {
      try {
        //console.log('STARTING FETCHDETAILS');
        //console.log(caseDetails);
        const response = await axios.post(`${modelUri}/getAnalytics`, {
          userConversation: userConversation,
          caseDetails: caseDetails,
          side: userSide
        });
        //console.log('FETCH DETAILS RESPONSE');
        setDetails(response.data);
        //console.log('CHECKING SETDETAILS');
      } catch (error) {
        // console.error('Error fetching case details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, [userConversation, caseDetails]);

  if (loading) {
    return <LoadingIcon />;
  } else if (details) {
    //console.log(details);
    return (
      <PageAnalytics  {...details} />
    )
  }
}


export default GetAnalytics;
