import { Popover, Transition } from '@headlessui/react';
import { X, Maximize2, Minimize2 } from 'lucide-react';
import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';

const IdealWayToGoPopover: React.FC<{ onClose: () => void }> = ({ onClose }) => {
    const [isFullScreen, setIsFullScreen] = useState(false);

    const toggleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
    };

    useEffect(() => {
        // GSAP animation for card sliding
        gsap.from(".step-card", {
            opacity: 0,
            y: 50,
            duration: 0.6,
            stagger: 0.3,
            ease: "power2.out",
        });
    }, []);

    return (
        <Popover>
            <Transition
                show={true}
                enter="transition duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition duration-150 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <Popover.Panel 
                    static 
                    className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
                >
                    <div className={`bg-creamyYellow dark:bg-gray-800 rounded-lg shadow-xl overflow-scroll ${isFullScreen ? 'w-full h-full' : 'w-11/12 h-5/6 max-w-4xl max-h-[90vh]'}`}>
                        <div className="flex justify-between items-center p-4 bg-black dark:bg-gray-700">
                            <h2 className="text-xl font-bold text-white">Ideal Way to Proceed</h2>
                            <div className="flex text-white space-x-2">
                                <button
                                    onClick={toggleFullScreen}
                                    aria-label={isFullScreen ? "Exit full screen" : "Enter full screen"}
                                >
                                    {isFullScreen ? <Minimize2 className="h-5 w-5" /> : <Maximize2 className="h-5 w-5" />}
                                </button>
                                <button
                                    onClick={onClose}
                                    aria-label="Close"
                                >
                                    <X className="h-5 w-5" />
                                </button>
                            </div>
                        </div>
                        <div className="bg-creamyYellow p-6 justify-center items-center rounded-lg mb-8">
                            <h1 className="text-3xl font-bold mb-6 text-gray-800 text-center">
                                Ideal Way to Go
                            </h1>
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                                
    <div className="step-card bg-gradient-to-r from-lightBlue to-skyBlue p-6 rounded-lg shadow-md">
        <h2 className="text-xl font-bold text-gray-800 mb-4">
            1. Review Case Facts
        </h2>
        <p className="text-gray-700 font-medium">
            Carefully examine the facts of the case to build a strong foundation. Understanding the full scope of details is crucial for a well-rounded legal strategy.
        </p>
    </div>

    <div className="step-card bg-gradient-to-r from-skyBlue to-brightBlue p-6 rounded-lg shadow-md">
        <h2 className="text-xl font-bold text-gray-800 mb-4">
            2. Consult Case Laws
        </h2>
        <p className="text-gray-700 font-medium">
            Explore applicable case laws and legal sections. Analyze how precedents can support your argument and understand key legal principles in play.
        </p>
    </div>

    <div className="step-card bg-gradient-to-r from-lightBlue to-skyBlue p-6 rounded-lg shadow-md">
        <h2 className="text-xl font-bold text-gray-800 mb-4">
            3. Formulate Your Arguments
        </h2>
        <p className="text-gray-700 font-medium">
            Develop compelling arguments for your side. Use your insights from case facts and legal analysis to construct a persuasive strategy, available 24/7 to accommodate your schedule.
        </p>
    </div>

    <div className="step-card bg-gradient-to-r from-skyBlue to-brightBlue p-6 rounded-lg shadow-md">
        <h2 className="text-xl font-bold text-gray-800 mb-4">
            4. Use Precise Language
        </h2>
        <p className="text-gray-700 font-medium">
            Articulate your arguments with precision. Focus on logical reasoning and back your points with relevant cases to strengthen your position.
        </p>
    </div>
</div>

                        </div>
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    );
};

export default IdealWayToGoPopover;
