import React, { FC, useState } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { Link } from "react-router-dom";
import { CaseDataType } from "data/types";
// import CaseDetailsPopover from "components/CaseTools/CaseDetail";

export interface StayCardProps {
  className?: string;
  data?: CaseDataType;
  size?: "default" | "small";
}


const DEMO_DATA =
{
  id: "1",
  _id: "dsfsdfsd",
  type: 'Case Study',
  title: 'The Benefits of Adopting a Cat',
  image: [
    'https://example.com/images/cat1.jpg',
    'https://example.com/images/cat2.jpg'
  ],
  href: 'https://example.com/case-studies/adopting-a-cat'
}


const CaseCard: FC<StayCardProps> = ({
  size = "default",
  className = "",
  data = DEMO_DATA,
}) => {
  const {
    id,
    type,
    title,
    image,
    href
  } = data;

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`StayCard_${id}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={image}
          href={href}
        />
        {/* <img>{image}</img> */}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <span className="text-sm text-white dark:text-neutral-400">
            {type}
          </span>
          <div className="flex items-center space-x-2">
            <h2
              className={` font-medium capitalize ${size === "default" ? "text-lg" : "text-base"
                }`}
            >
              <span className="line-clamp-1 text-white">{title}</span>
            </h2>
          </div>

        </div>

        <div className=" border-b border-neutral-100 dark:border-neutral-800">


        </div>


      </div>

    );
  };




  const handleClick = () => {
    sessionStorage.setItem("caseId", id)
    window.location.href = "/caseDetails"
  }
  return (

    <div
      className={`nc-StayCard group shadow-xl relative bg-peach dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition hover:-translate-y-1 transition-shadow duration-300  ${className}`}
      data-nc-id="StayCard"

    >
      {renderSliderGallery()}
      <button onClick={handleClick}>{renderContent()}</button>
    </div>
  );
};

export default CaseCard;
