import React, { createRef, useEffect } from 'react';
import ParallaxText from './VelocityScroller';
import { Gavel, Users, UserPlus, Sword, Database } from 'lucide-react';
import gsap from 'gsap';
import HeadingSvg from './headingSvg';

const features = [
  {
    title: 'Prepare Yourself',
    description: 'In court simulated environment, learn how to present and formulate case arguments.',
    icon: '📝',
  },
  {
  title: 'Contextual Learning',
  description: 'Practice using real landmark cases from the Indian judiciary. Enhance your understanding through hands-on experience.',
  icon: '📚',
  },
  {
    title: 'Analyze and Improve',
    description: 'Analyze your case performance and improve your skills based on the feedback you receive.',
    icon: '📊',
  },
];

function LandingPage() {
 
  return (
    <div className="landing-page w-full min-h-screen flex flex-col md:flex-row text-black pb-6 ">
      <div className="z-10 flex flex-col justify-center items-center">
       
        <h1 className="tagline text-4xl md:text-6xl font-bold text-gray-900 mt-10">
        <div className="relative inline-block">
            <div className='mb-4'>
              <HeadingSvg />
          </div>
        
          
        </div>
      </h1>

        <p className="text-lg mt-4 text-center text-gray-700">
          Immersive AI simulation for arguing cases, receiving scores, verdicts & more. Practice cases and host mootcourts.
         
        </p>
        <div className="mt-4 flex justify-center items-center space-x-4">
        <span className="text-lg  font-semibold text-blue-800">🕒 24/7 case practice</span>
        <span className="text-lg  font-semibold text-blue-800">⚖️ Real-world cases from Indian judiciary</span>
        <span className="text-lg font-semibold text-blue-800">🌐 Cases across all legal domains</span>
        </div>
        <button
          onClick={() => (window.location.href = "/mootcourt")}
          className="bg-gradient-to-r z-10 from-pink-400 to-red-500 hover:from-pink-500 hover:to-orange-500 text-white px-4 py-2 mt-4 rounded-full focus:outline-none transition-transform transform hover:scale-110 focus:scale-110 "
        >
          TAKE ME THERE!
      </button>

          <div className="z-10 mt-8 border border-black rounded-lg p-6 shadow-md">
        <div className=" flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
          <div className="flex items-center space-x-4">
            <div className="flex -space-x-2">
              <img className="w-10 h-10 rounded-full border-2 border-white" src="/avatar1.jpeg" alt="Avatar 1" />
              <img className="w-10 h-10 rounded-full border-2 border-white" src="/avatar2.png" alt="Avatar 2" />
              <img className="w-10 h-10 rounded-full border-2 border-white" src="/avatar3.png" alt="Avatar 3" />
              <img className="w-10 h-10 rounded-full border-2 border-white" src="/avatar4.png" alt="Avatar 4" />
            </div>
         
          </div>
            <div className=" flex items-center space-x-4">
                 <span className="text-gray-700 font-medium"> <strong className="text-blue-600">150+</strong> users</span>
            <Database className="w-10 h-10 text-blue-600" />
            <span className="text-gray-700 font-medium"><strong className="text-blue-600">100+</strong> cases</span>
          </div>
        </div>
      
        </div> 
        
        <section className="z-10 py-10">
          <div className="text-center mb-6">
            <h2 className="text-3xl md:text-4xl font-semibold text-gray-800">Why Us?</h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div
                key={index}
                className="text-center p-6 bg-peach hover:scale-105 shadow-lg rounded-lg transform transition-transform duration-300"
              >
                <div className="text-4xl mb-4">{feature.icon}</div>
                <h3 className="text-xl font-medium text-white">{feature.title}</h3>
                <p className="mt-2 text-white">{feature.description}</p>
              </div>
            ))}
            
           
</div>

    </section>
        

          </div>
          

      
    </div>
  );
}

export default LandingPage;
