import SectionHero from "components/SectionHero/SectionHero";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import React, { useState } from "react";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { TaxonomyType } from "data/types";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionVideos from "./SectionVideos";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import Testimonials from "components/Testimonials/Testimonials";
import PageContact from "containers/PageContact/PageContact";
import ContactSection from "components/ContactUs/Contact";
import { Alert } from "shared/Alert/Alert";
import LandingPage from "./LandingPage";
import Loader from "./Loader";
import Header from "components/Header/Header";
import ParallaxText from "./VelocityScroller";
import SubscriptionSection from "./SubcriptionSection";
import { Link } from "react-router-dom";

function PageHome() {
  const [isLoadingComplete, setIsLoadingComplete] = useState(true);

  const handleLoadingComplete = () => {
    setIsLoadingComplete(true);
  };

  return (
    <div className="nc-PageHome bg-creamyYellow relative overflow-hidden">
     
      <BgGlassmorphism />

      <div className="container mb-24 lg:mb-28">
        
        <div id="hero-section" className="w-full">
          {/* <Loader onComplete={handleLoadingComplete} /> */}
    
          <div className={`transition-opacity duration-1000 ${isLoadingComplete ? 'opacity-100' : 'opacity-0'}`}>
            {isLoadingComplete &&
            <div className="z-50">
              <LandingPage />
              </div>}
          </div>
        </div>

     
        <div className="flex flex-col mt-10">
          {isLoadingComplete && (
            <div className="flex flex-col overflow-hidden">
             
              {sessionStorage.getItem("email") ? (

                <div className="md:inline mt-4 md:mt-6 lg:mt-8">
                <ParallaxText baseVelocity={-5}>AI MOOTCOURT</ParallaxText>
                <ParallaxText baseVelocity={5}>LAW TRAINING</ParallaxText>
              </div> 

              ) : (
                  <div className="bg-gradient-to-r from-blue-500 z-30 to-green-500 text-white text-center py-4 px-6 rounded-lg shadow-lg">
                  <h3 className="text-lg font-semibold mb-1">Sign up today and get 10 free trial cases!</h3>
                  <p className="text-sm">Start practicing your cases right away with our free trial offer.</p>
                  <Link
                    className="mt-3 inline-block bg-white text-blue-600 font-bold py-2 px-4 rounded-full hover:bg-gray-200 transition duration-300"
                    to="/signup"
                  >
                    Sign Up Now
                  </Link>
                </div>
              )}

              
              <div id="features-section" className="w-full mt-4 md:mt-6 lg:mt-8">
                <SectionOurFeatures />
              </div>
              
              <div className="w-full mt-4 md:mt-6 lg:mt-8">
                <Testimonials />
              </div>

              <div id="how-it-works" className="w-full mt-4 md:mt-6 lg:mt-8">
                <SectionHowItWork />
              </div>

              <div className="w-full mt-4 md:mt-6 lg:mt-8">
                <SubscriptionSection />
              </div>

              <div id="videos-section" className="w-full mt-4 md:mt-6 lg:mt-8">
                <SectionVideos />
              </div>

              <div id="contact-us" className="w-full mt-4 md:mt-6 lg:mt-8">
                <ContactSection />
              </div>

            
            </div>
          )}
        </div>
      </div>

      {isLoadingComplete && (
        <a
          href="/mootCourt"
          className="animate-bounce z-50 fixed bottom-4 right-4 bg-red-600 text-white rounded-full w-16 h-16 md:w-20 md:h-20 flex items-center justify-center shadow-lg hover:bg-red-700 transition-colors"
        >
          <span className="text-sm md:text-lg font-bold">Try Now</span>
        </a>
      )}
    </div>
  );
}

export default PageHome;
