import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { apiUri } from '../../constants';
import Alert from 'shared/Alert/Alert';
import PreMadeCasePopover from './SelectPreMadeCasePopover';
import { off } from 'process';

interface CreateLobbyDirectProps {
    className?: string;
    onClose: () => void;
    setOpenTeamSelect: React.Dispatch<React.SetStateAction<boolean>>;
    setEnteredLobby: React.Dispatch<React.SetStateAction<string>>;
};

const CreateLobbyDirect: React.FC<CreateLobbyDirectProps> = ({ onClose, setOpenTeamSelect, setEnteredLobby, className = "" }) => {
    const [lobbyName, setLobbyName] = useState('');
    const [password, setPassword] = useState('');
    const [isLobbyNameUnique, setIsLobbyNameUnique] = useState(true);
    const [isCustomCase, setIsCustomCase] = useState(false);
    const [thingsToKnow, setThingsToKnow] = useState('');
    const [caseDetails, setCaseDetails] = useState('');
    const [caseFile, setCaseFile] = useState<File | null>(null);
    const [error, setError] = useState('');
    const [isPremadeOpen, setisPremadeOpen] = useState(false)
    const [isNothingSelected, setIsNothingSelected] = useState(false)
    const [isError, setIsError] = useState(false)

    const checkLobbyNameUnique = async () => {
        try {
            // const response = await axios.post(`${apiUri}/checkMootCourtName`, { hostedCourtName: lobbyName });
            // setIsLobbyNameUnique(response.data.isUnique);
            setIsLobbyNameUnique(true);
        } catch (err) {
            console.error('Error checking lobby name uniqueness:', err);
        }
    };

    const handlePopoverClose = () => {
        setisPremadeOpen(false)
        if (sessionStorage.getItem("MootcourtSelectLobby") == "" || sessionStorage.getItem("MootcourtSelectLobby") == null) {
            setIsNothingSelected(true)
        }
    }

    const handleCreateLobby = async () => {

        if (!sessionStorage.getItem("email")) {
            window.location.href = "/login"
        }
        
        if (!lobbyName) {
            setError('Lobby Name is required');
            setIsError(true)
            return;
        }

        if (!isLobbyNameUnique) {
            setError('Lobby Name is already taken');
            setIsError(true)
            return;
        }

        if (isCustomCase === false) {
            if (sessionStorage.getItem("MootcourtSelectLobby") == "" || sessionStorage.getItem("MootcourtSelectLobby") == null) {
                setError('No case was selected from preMade case.')
                setIsError(true)
            }
            // return
        }

        let formData = new FormData();
        //console.log("HOSTEDCOURTNAME", lobbyName);
        //console.log("PAsswrod", password);
        formData.append('hostedCourtName', lobbyName);
        formData.append('password', password);

        if (isCustomCase && caseFile) {
            //console.log("IS a custom case and custom file sfsd");
            formData.append('thingsToKnow', thingsToKnow);
            formData.append('caseDetails', caseDetails);
            formData.append('caseFile', caseFile);
        }

        try {

            // //console.log('Aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
            // //console.log(formData)
            //console.log("THis is the form data in createLobbyForTeam");
            //console.log(formData);
            for (let [key, value] of formData.entries()) {
                //console.log(`${key}: ${value}`);
            }

            // console.log("hiiiiiiiiiiiiiiiiiiiiiiiiiiiiii")
            const response = await axios.post(`${apiUri}/hostMootCourt`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });


            console.log(response.data)

            if (response.data.success) {
                setOpenTeamSelect(true);
                onClose();
                // window.location.href = '/collegeMootcourt'

            }
            else {
                //console.log("Error on creating lobby");
                //console.log(response.data.message);
                setError(response.data.message);
                console.error(response.data.message);
            }

        } catch (err) {
            setError('Error creating lobby');
            console.error(err);
        }
    };


    useEffect(() => {
        if (lobbyName) {
            checkLobbyNameUnique();
        }
    }, [lobbyName]);

    useEffect(() => {
        if (isError) {
            const timer = setTimeout(() => setIsError(false), 2000);
            return () => clearTimeout(timer);
        }
    }, [isError]);

    return (
        <div className={`container mx-auto my-12 p-6 bg-creamyYellow border border-black border-3 dark:bg-gray-800 rounded-lg shadow-md ${className}`}>

            <h2 className="text-3xl font-bold mb-6 text-neutral-900 dark:text-neutral-100">
                Create Lobby
            </h2>

            <div className="mb-4">
                <label className="block text-xl font-medium text-neutral-900 dark:text-neutral-100">
                    Lobby Name
                </label>
                <span className='text-sm'>*Must be UNIQUE</span>
                <input
                    type="text"
                    className={`mt-1 p-2 w-full border rounded-md focus:ring ${isLobbyNameUnique ? 'border-gray-300' : 'border-red-500'}`}
                    value={lobbyName}
                    onChange={(e) => {
                        //console.log("LObby Name changing", e.target.value);
                        setLobbyName(e.target.value)
                        setEnteredLobby(e.target.value)
                    }}
                />
                {!isLobbyNameUnique && (
                    <p className="text-red-500 text-sm mt-1">Lobby Name is already taken</p>
                )}
            </div>
            <div className="mb-4">


                <div className="mb-4">
                    <label className="block text-xl font-medium text-neutral-900 dark:text-neutral-100">
                        Password
                    </label>
                    <input
                        type="password"
                        className="mt-1 p-2 w-full border rounded-md focus:ring border-gray-300"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>

                {isError && (
                    <Alert children={error} type='error' />
                )}

                <div className="flex justify-end">
                    <button
                        onClick={() => {
                            handleCreateLobby();
                        }}
                        className={`${isNothingSelected ? "disabled:cursor-not-allowed" : ""} bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-700 mr-2`}

                    >
                        Create Lobby
                    </button>
                    <button
                        onClick={onClose}
                        className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CreateLobbyDirect;





