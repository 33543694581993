import React from 'react';

const DescriptionRenderer: React.FC<{ description: string }> = ({ description }) => {
    const parsedDescription = description.split(/(Introduction|Facts:-|Issues Raised|FACTS OF THE CASE|Introduction:-|Issues Raised:-|Facts| INTRODUCTION| FACTS| ISSUES RAISED )/gi);

    return (
        <div className="text-black dark:text-neutral-300">
            {parsedDescription.map((part, index) => {
                const isSubheading = part.toLowerCase() === 'introduction' || part.toLowerCase() === 'facts:-' || part.toLowerCase() === 'issues raised'
                    || part.toLowerCase() === 'facts of the case'|| part.toLowerCase() === 'facts'|| part.toLowerCase() === 'issues' || part.toLowerCase() === 'issues:-';

                
                if (isSubheading) {
                    return (
                        <h2 key={index} className="text-xl font-bold mt-4">
                            {part}
                        </h2>
                    );
                }

                
                if (parsedDescription[index - 1]?.toLowerCase() === 'facts:-' || parsedDescription[index - 1]?.toLowerCase() === 'issues raised'
                || parsedDescription[index - 1]?.toLowerCase() === 'facts of the case') {
                    const listItems = part.split(/(?:\d+\.\s*|[•])/).filter(item => item.trim());
                    return (
                        <ul key={index} className="list-disc ml-6 mt-2">
                            {listItems.map((item, listIndex) => (
                                <li key={listIndex} className="mt-1">
                                    {item.trim()}
                                </li>
                            ))}
                        </ul>
                    );
                }

                
                const containsListPattern = /\d+\.\s*|[•]/.test(part);
                if (containsListPattern) {
                    const listItems = part.split(/(?:\d+\.\s*|[•])/).filter(item => item.trim());
                    return (
                        <ul key={index} className="list-disc ml-6 mt-2">
                            {listItems.map((item, listIndex) => (
                                <li key={listIndex} className="mt-1">
                                    {item.trim()}
                                </li>
                            ))}
                        </ul>
                    );
                }

                
                return (
                    <p key={index} className="mt-2">
                        {part.trim()}
                    </p>
                );
            })}
        </div>
    );
};

export default DescriptionRenderer;
