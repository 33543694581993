import OpenAI from "openai";
import path from 'path';
import fs from 'fs';
import localforage from 'localforage';
import { Howl } from 'howler';
import { Buffer } from "buffer";


const openai = new OpenAI( {apiKey: process.env.REACT_APP_OPENAI_API_KEY,dangerouslyAllowBrowser:true});
let currentSound: Howl | null = null;

async function textToSpeech(str: string) {
    if (currentSound) {
        currentSound.stop();
        currentSound = null;
    }

    const mp3 = await openai.audio.speech.create({
        model: "tts-1",
        voice: "alloy",
        input: str,
    });

    const buffer = Buffer.from(await mp3.arrayBuffer());

    await localforage.setItem('temp_speech', buffer);

    //console.log("stored successfully");

    const storedBuffer = await localforage.getItem<Buffer>('temp_speech');
    if (storedBuffer) {
        const blob = new Blob([storedBuffer], { type: 'audio/mp3' });
        const url = URL.createObjectURL(blob);

        currentSound = new Howl({
            src: [url],
            format: ['mp3'],
            onend: () => {
                //console.log('Sound played successfully.');
                URL.revokeObjectURL(url);
                localforage.removeItem('temp_speech'); 
                currentSound = null;
            },
            onloaderror: (id, err) => {
                console.error('Failed to load sound:', err);
            },
            onplayerror: (id, err) => {
                console.error('Failed to play sound:', err);
                currentSound?.once('unlock', () => {
                    currentSound?.play();
                });
            }
        });

        currentSound.play();
    } else {
        console.error('Failed to retrieve sound from localforage.');
    }
}

export default textToSpeech;
