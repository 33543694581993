import { CheckIcon } from "@heroicons/react/24/solid";
import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ContactSection from "components/ContactUs/Contact";
import DiscountedRazorPayButton from "./DiscountedRazorpayButton";

export interface PageSubcriptionProps {
  className?: string;
}

export interface PricingItem {
  isPopular: boolean;
  name: string;
  pricing: string;
  desc: string;
  per: string;
  features: string[];
}

const pricings: PricingItem[] = [
  {
    isPopular: true,
    name: "Individual",
    pricing: "INR 199",
    per: "/mo",
    features: ["Unlimited Mootcourt access", "Avyukta Bot - The one stop case guide", "Advanced analytics", "24/7 Customer Support"],
    desc: ``,
  },
  // {
  //   isPopular: true,
  //   name: "Basic",
  //   pricing: "$15",
  //   per: "/mo",
  //   features: [
  //     "Everything in Starter",
  //     "100 Builds",
  //     "Progress Reports",
  //     "Premium Support",
  //   ],
  //   desc: ``,
  // },
  {
    isPopular: false,
    name: "Enterprise",
    pricing: "Contact Us",
    per: "",
    features: [
      "Unlimited access to all memebers",
      "Feedback and support",
      "Advanced Analytics",
      "24/7 customer support",
    ],
    desc: ``,
  },
];

const handleClick = () =>  {
  document.getElementById('contact-us')?.scrollIntoView({ behavior: 'smooth' });
}

const handleLoginClick = () => {
  window.location.href = "/login"
}
const DiscountedPageSubscription: FC<PageSubcriptionProps> = ({ className = "" }) => {

    const isCoupon = sessionStorage.getItem("appliedCoupon")

    if (isCoupon === null || isCoupon === "") {
        window.location.href = "/"
    }

  const renderPricingItem = (pricing: PricingItem, index: number) => {
    return (
      <div
        key={index}
        className={`h-full relative px-6 py-8 rounded-3xl hover:bg-gray-100 border-2 flex flex-col overflow-hidden ${
          pricing.isPopular
            ? "border-primary-500"
            : "border-neutral-100 dark:border-neutral-700"
        }`}
      >
        {pricing.isPopular && (
          <span className="bg-primary-500 text-white px-3 py-1 tracking-widest text-xs absolute right-3 top-3 rounded-full z-10">
            POPULAR
          </span>
        )}
        <div className="mb-8">
          <h3 className="block text-sm uppercase tracking-widest text-neutral-6000 dark:text-neutral-300 mb-2 font-medium">
            {pricing.name}
          </h3>
          <h2 className="text-5xl leading-none flex items-center text-neutral-900 dark:text-neutral-300">
            <span>{pricing.pricing}</span>
            <span className="text-lg ml-1 font-normal text-neutral-500">
              {pricing.per}
            </span>
          </h2>
        </div>
        <nav className="space-y-4 mb-8">
          {pricing.features.map((item, index) => (
            <li className="flex items-center" key={index}>
              <span className="mr-4 inline-flex flex-shrink-0 text-primary-6000">
                <CheckIcon className="w-5 h-5" aria-hidden="true" />
              </span>
              <span className="text-neutral-700 dark:text-neutral-300">
                {item}
              </span>
            </li>
          ))}
        </nav>
          <div className="flex flex-col mt-auto">
          {pricing.isPopular ? (
            sessionStorage.getItem("loggedIn") ? <DiscountedRazorPayButton/> :  <button className="bg-blue-600 z-30 hover:bg-blue-700 text-white py-2 px-6 rounded-full transition-all duration-300 text-2xl"onClick={handleLoginClick}> To subscribe, kindly login</button>
          ) : (
          
              <button className="bg-blue-600 z-30 hover:bg-blue-700 text-white  py-2 px-6 rounded-full transition-all duration-300 text-2xl" onClick={handleClick}>Contact Us</button>
           
          )}
          <p className="text-xs text-neutral-500 dark:text-neutral-400 mt-3">
            {pricing.desc}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-PageSubcription container pb-24 lg:pb-32 ${className}`}
      data-nc-id="PageSubcription"
    >
      <header className="text-center max-w-2xl mx-auto my-20">
        <h2 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          <span className="mr-4 text-3xl md:text-4xl leading-none">💎</span>
          Subscription
        </h2>
        <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
          We provide access to 10 cases on signup, but for full access subscribe.
        </span>
      </header>
      <section className="text-neutral-600 text-sm md:text-base overflow-hidden">
        <div className="grid lg:grid-cols-2 gap-5 xl:gap-8">
          {pricings.map(renderPricingItem)}
        </div>
      </section>

      <section id="contact-us">
        <ContactSection/>
      </section>
    </div>
  );
};

export default DiscountedPageSubscription;
