/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useRef, useState } from "react";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { Message } from "containers/PageCourt/types";
import ConversationLog from "containers/PageCourt/conversationLog";

interface SidebarProps {
    setExpand: (value: boolean) => void;
    messages: Message[];
    conversationLogRef: React.RefObject<HTMLDivElement>;
    isExpand: boolean;
    setIsExpand: React.Dispatch<React.SetStateAction<boolean>>;
}

const Sidebar: FC<SidebarProps> = ({ isExpand, setIsExpand, setExpand, messages, conversationLogRef }) => {
    const username = "Conversation";
    const company = "History";
    const profilePic = "/conversation.gif";
    const link = "/";

    const [openedMenu, setOpenedMenu] = useState<Record<string, any>>({});
    const [activeName, setActiveName] = useState("");
    const activeLink = window.location.pathname;

    const listRef = useRef<Record<string, HTMLUListElement | null>>({});


    const [isExpandOnHover, setIsExpandOnHover] = useState(false);

    const handleHoverExpand = (value: boolean) => {
        if (!isExpand) {
            setIsExpandOnHover(value);
        }
    };

    const handleNavigate = (path: string) => {
        setActiveName(path);
    };

    const handleToggle = (name: string) => {
        const rootEl = name.split(".")[0];

        if (openedMenu[name]?.open === true) {
            setOpenedMenu((prevState) => ({
                ...prevState,
                [name]: {
                    open: false,
                    height: "0px"
                },
                [rootEl]: {
                    open: rootEl === name ? false : true,
                    height: `${(listRef.current[rootEl]?.scrollHeight || 0) -
                        (listRef.current[name]?.scrollHeight || 0)
                        }px`
                }
            }));
        } else {
            setOpenedMenu((prevState) => ({
                ...prevState,
                [name]: {
                    open: true,
                    height: `${listRef.current[name]?.scrollHeight || 0}px`
                },
                [rootEl]: {
                    open: true,
                    height: `${(listRef.current[rootEl]?.scrollHeight || 0) +
                        (listRef.current[name]?.scrollHeight || 0)
                        }px`
                }
            }));
        }
    };



    return (
        <nav
            role="navigation"
            className={[
                " bg-peach overflow-y-auto touch-pan-y scroll-smooth border-r border-slate-100 shadow-sm absolute inset-y-0 left-0",
                "duration-300 ease-in-out md:fixed md:translate-x-0",
                `${isExpand
                    ? "bg-peach w-72"
                    : isExpandOnHover
                        ? "bg-peach/70 w-72 backdrop-blur-md"
                        : "bg-peach w-20"
                }`
            ].join(" ")}
        >
            <button
                className="absolute z-50 top-16 -right-3 bg-creamyYellow hover:bg-slate-100 text-slate-500 p-2 rounded-full border border-slate-200"
                onClick={() => {
                    setIsExpand(!isExpand);
                    setExpand(!isExpand);
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`${isExpand ? "rotate-0" : "rotate-180"
                        } transform duration-500 h-8 w-8`}
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path
                        fillRule="evenodd"
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clipRule="evenodd"
                    />
                </svg>
            </button>
            <div
                onMouseEnter={() => handleHoverExpand(true)}
                onMouseLeave={() => handleHoverExpand(false)}
                className={`relative h-screen overflow-hidden`}
            >
                <SimpleBar style={{ height: "100%" }} autoHide={true}  >
                    <div className="text-slate-500">
                        <div className="my-6 flex flex-col items-center h-44 overflow-x-hidden">
                            <a
                                href={link}
                                className={`text-center flex flex-col items-center justify-center`}
                            >
                                <div
                                    className={`flex justify-center items-center rounded-full border-4 border-white overflow-hidden duration-300 ${isExpand
                                        ? "h-28 w-28"
                                        : isExpandOnHover
                                            ? "h-28 w-28"
                                            : "h-12 w-12"
                                        }`}
                                >
                                    <img src={profilePic} className="block" alt="" />
                                </div>
                                <div
                                    className={`text-base font-semibold text-slate-700 mt-3 truncate duration-300 ${isExpand ? "" : isExpandOnHover ? "" : "w-0 h-0 opacity-0"
                                        }`}
                                >
                                    {username}
                                </div>
                                <div
                                    className={`duration-300 text-sm text-slate-500 truncate ${isExpand ? "" : isExpandOnHover ? "" : "w-0 h-0 opacity-0"
                                        }`}
                                >
                                    {company}
                                </div>
                            </a>
                        </div>
                        <div className={`${isExpandOnHover || isExpand ? "" : "invisible"} flex flex-col  `}  >
                            {messages.length <= 0 ? (<span>No Messages Yet :(</span>) : (
                                <ConversationLog messages={messages} conversationRef={conversationLogRef} ></ConversationLog>
                            )
                            }
                        </div>
                    </div>
                </SimpleBar>
            </div>
        </nav>
    );
};

export default Sidebar;
