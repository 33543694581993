import React, { useState, useRef, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { X, Maximize2, Minimize2, Send } from 'lucide-react';
import axios from 'axios';
import LoadingIcon from 'components/Loading/LoadingIcon';
import { apiUri } from '../../constants';
import LoadingButton from 'components/Loading/LoadingButton';

interface InteractWithCaseProps {
    onClose: () => void;
    document: string;
}

interface Message {
    role: 'user' | 'assistant';
    content: string;
}

const InteractWithCase: React.FC<InteractWithCaseProps> = ({ onClose, document }) => {
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [messages, setMessages] = useState<Message[]>([]);
    const [input, setInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const messagesEndRef = useRef<HTMLDivElement>(null);

    const toggleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(scrollToBottom, [messages]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!input.trim()) return;

        const userMessage: Message = { role: 'user', content: input };
        setMessages(prev => [...prev, userMessage]);
        setInput('');
        setIsLoading(true);

        try {
            const response = await axios.post(`${apiUri}/interactWithCase`, { question: input, caseInput: document });
            const assistantMessage: Message = { role: 'assistant', content: response.data.data };
            setMessages(prev => [...prev, assistantMessage]);
        } catch (error) {
            console.error('Error interacting with the case:', error);
            const errorMessage: Message = { role: 'assistant', content: 'Sorry, there was an error processing your request.' };
            setMessages(prev => [...prev, errorMessage]);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Popover>
            <Transition
                show={true}
                enter="transition duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition duration-150 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <Popover.Panel 
                    static 
                    className="fixed inset-0 flex items-center rounded-lg justify-center bg-black bg-opacity-50 z-50"
                >
                    <div className={`bg-white dark:bg-gray-800 rounded-lg shadow-xl flex flex-col ${
                        isFullScreen ? 'w-full h-full' : 'w-11/12 h-5/6 max-w-4xl max-h-[90vh]'
                    }`}>
                        <div className="sticky top-0 flex justify-between items-center p-4 bg-gray-100 dark:bg-gray-700 z-10">
                            <h2 className="text-xl font-bold text-gray-800 dark:text-gray-200">Interact with Case</h2>
                            <div className="flex space-x-2">
                                <button
                                    onClick={toggleFullScreen}
                                    aria-label={isFullScreen ? "Exit full screen" : "Enter full screen"}
                                    className="focus:outline-none"
                                >
                                    {isFullScreen ? <Minimize2 className="h-5 w-5" /> : <Maximize2 className="h-5 w-5" />}
                                </button>
                                <button
                                    onClick={onClose}
                                    aria-label="Close"
                                    className="focus:outline-none"
                                >
                                    <X className="h-5 w-5" />
                                </button>
                            </div>
                        </div>
                        <div className="flex-grow overflow-y-auto p-4 space-y-4">
                            {messages.map((message, index) => (
                                <div key={index} className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}>
                                    <div className={`max-w-3/4 p-3 rounded-lg ${
                                        message.role === 'user' ? 'bg-blue-500 text-white' : 'bg-gray-200 dark:bg-gray-600 text-gray-800 dark:text-gray-200'
                                    }`}>
                                        {message.content}
                                    </div>
                                </div>
                            ))}
                            <div ref={messagesEndRef} />
                        </div>
                        <form onSubmit={handleSubmit} className="sticky bottom-0 p-4 bg-gray-100 dark:bg-gray-700">
                            <div className="flex space-x-2">
                                <input
                                    type="text"
                                    value={input}
                                    onChange={(e) => setInput(e.target.value)}
                                    placeholder="Ask a question about the case..."
                                    className="flex-grow p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-600 dark:text-white"
                                />
                                <button
                                    type="submit"
                                    disabled={isLoading}
                                    className="p-2 text-black rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50"
                                >
                                    {isLoading ? <LoadingButton /> : <Send className="h-5 w-5" />}
                                </button>
                            </div>
                        </form>
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    );
};

export default InteractWithCase;