import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { apiUri } from '../../constants';
import LoadingIcon from 'components/Loading/LoadingIcon';
import Alert from 'shared/Alert/Alert';

interface UserRegisterProp {
  caseTitle: string;
  collegeName: string;
  time: string;
  cost: string;
  prizes: string[];
  specialReq: string;
  dateOfStart: string;
  dateOfEnd: string; 
  teamSize: string;
  description: string;
  casePdf: Blob;
}

const MootRegistrationForm: React.FC = () => {
  const [formData, setFormData] = useState<UserRegisterProp>({
    caseTitle: '',
    collegeName: '',
    time: '',
    cost: '',
    prizes: [''],
    specialReq: '',
    dateOfStart: '',
    dateOfEnd: '',
    teamSize: '',
    description: '',
    casePdf: new Blob(),
  });

    const [loading, setLoading] = useState(false)
  const [isErr, setIsErr] = useState(false)
  const [mootCourtId, setMootCourtId] = useState("")

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
    
    useEffect(() => {
    if (isErr) {
      const timer = setTimeout(() => setIsErr(false), 2000);
      return () => clearTimeout(timer); 
    }
  }, [isErr]);


  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      //console.log(file)
    if (file) {
      setFormData({ ...formData, casePdf: file });
    } 
  };

  const handlePrizesChange = (index: number, value: string) => {
    const updatedPrizes = formData.prizes.map((prize, i) =>
      i === index ? value : prize
    );
    setFormData({ ...formData, prizes: updatedPrizes });
  };

  const addPrizeField = () => {
    setFormData({ ...formData, prizes: [...formData.prizes, ''] });
  };

  const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      setLoading(true)
    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (key === 'prizes') {
        value.forEach((prize: string, index: number) => {
          formDataToSend.append(`prizes[${index}]`, prize);
        });
      } else {
        formDataToSend.append(key, value as string | Blob);
      }
    });

      try {
        //console.log(formDataToSend)
      const response = await axios.post(`${apiUri}/collegeMootReg`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
          
        setMootCourtId(response.data._id)
         
      } catch (error) {
         
        setLoading(false)
      }
    
    
    try {
  
        const r = await axios.post(`${apiUri}/initiateMootTeams`, { id: mootCourtId })
        
         setLoading(false)
          window.location.href = "/registerSuccess"
    }

    catch (error) {
      setLoading(false)
    }

    
  };

  return (
      <div className="max-w-2xl mx-auto p-6 my-3 bg-peach rounded-lg shadow-md">
          {loading ? <LoadingIcon /> : (
              
              <div>
        {isErr && <Alert children="Error registering. Please try again later." type="error"/>}
      <h2 className="text-2xl font-semibold text-white mb-4">Register Your Moot Court</h2>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="mb-4">
          <label htmlFor="caseTitle" className="block text-white font-medium mb-2">
            Case Title
          </label>
          <input
            type="text"
            id="caseTitle"
            name="caseTitle"
            value={formData.caseTitle}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="collegeName" className="block text-white font-medium mb-2">
            College Name
          </label>
          <input
            type="text"
            id="collegeName"
            name="collegeName"
            value={formData.collegeName}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="time" className="block text-white font-medium mb-2">
            Time
          </label>
          <input
            type="text"
            id="time"
            name="time"
            value={formData.time}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="cost" className="block text-white font-medium mb-2">
            Cost
          </label>
          <input
            type="text"
            id="cost"
            name="cost"
            value={formData.cost}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="prizes" className="block text-white font-medium mb-2">
            Prizes
          </label>
          {formData.prizes.map((prize, index) => (
            <div key={index} className="flex items-center mb-2">
              <input
                type="text"
                id={`prize-${index}`}
                name={`prize-${index}`}
                value={prize}
                onChange={(e) => handlePrizesChange(index, e.target.value)}
                required
                className="w-full px-3 py-2 border rounded-md"
              />
            </div>
          ))}
          <button
            type="button"
            onClick={addPrizeField}
            className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md"
          >
            Add Another Prize
          </button>
        </div>

        <div className="mb-4">
          <label htmlFor="specialReq" className="block text-white font-medium mb-2">
            Special Requirements
          </label>
          <textarea
            id="specialReq"
            name="specialReq"
            value={formData.specialReq}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="dateOfStart" className="block text-white font-medium mb-2">
            Date of Start
          </label>
          <input
            type="date"
            id="dateOfStart"
            name="dateOfStart"
            value={formData.dateOfStart}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="dateOfEnd" className="block text-white font-medium mb-2">
            Date of End
          </label>
          <input
            type="date"
            id="dateOfEnd"
            name="dateOfEnd"
            value={formData.dateOfEnd}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="teamSize" className="block text-white font-medium mb-2">
            Team Size
          </label>
          <input
            type="text"
            id="teamSize"
            name="teamSize"
            value={formData.teamSize}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="description" className="block text-white font-medium mb-2">
            Description
          </label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="casePdf" className="block text-white font-medium mb-2">
            Upload Case PDF
          </label>
          <input
            type="file"
            id="casePdf"
            name="casePdf"
            accept="application/pdf"
            onChange={handleFileChange}
            required
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>

        <div className="text-right">
          <button
            type="submit"
            className="px-6 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition"
          >
            Register
          </button>
        </div>
                  </form>
                  </div>
                )}
    </div>
  );
};

export default MootRegistrationForm;
