// src/components/RestP2P.tsx
import React, { useEffect, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import axios from 'axios';
import { apiUri, modelUri } from '../../constants';
import { useNavigate } from 'react-router-dom';
import io, { Socket } from 'socket.io-client';

const RestP2P: React.FC<{ onClose: () => void, socket: Socket, sessionId: string, userId: string, userIdFriend: string, fullUserConversation: string, fullFriendConversation: string }> = ({ onClose, socket, sessionId, userId, userIdFriend, fullUserConversation, fullFriendConversation }) => {

    const navigate = useNavigate();
    const [caseDetails, setcaseDetails] = useState<string>("")
    const hostedCourtName = sessionStorage.getItem("hostedCourtName")

    const fetchIdealConversation = async () => {
        const caseId = sessionStorage.getItem('caseId');
        if (!caseId) return;

        try {
            const response = await axios.post(`${apiUri}/fetchArgument`, { caseId });
            //console.log("THIS IS FETCH ARGUMENT");
            //console.log(response.data);
            if (response.data.success) {
                setcaseDetails(response.data.data.initialPrompt);
            }
        } catch (error) {
            //console.log(error);
        }
    };
    const handleGetPeerDecision = async () => {
        const response = await axios.post(`${modelUri}/getPeerDecision`, {
            user1: userId,
            user2: userIdFriend,
            user1Convo: fullUserConversation,
            user2Convo: fullFriendConversation,
            idealConvo: caseDetails
        })
        // //console.log("RESPONSE ON GET ANALYTICS: ")
        // //console.log(response)
        //console.log(response.data)
        // //console.log(response.data.data);
        // //console.log(userId);
        if (socket) {
            //console.log("SOCKET is still valid to send to the other user")
            if (userId == "Prosecutor" || userId == "Defendant") {
                //console.log("CEHCLJKCECECE");
                socket.emit("sendCourt", hostedCourtName, "Judge", response.data);
            }
            else {
                socket.emit('groupMessage', response.data, sessionId);
            }
        }
        onClose();
        const state = response.data;
        // handleRemoveCourt();
        navigate("/p2pAnalytics", { state });
    }
    useEffect(() => {
        handleGetPeerDecision();
    }, [caseDetails])


    const handleRemoveCourt = async () => {
        //console.log("HANDLE remove court called");
        const response = await axios.post(`${apiUri}/removeHostedCourt`, {
            hostedCourtName: hostedCourtName,
            //   teamName: teamName,
        })
        // //console.log("RESPONSE REceived for handle remove court");
        if (response.data.success) {
            //console.log('Successfully removed court.')
        }
        else {
            //console.log('Failed to remove court.')
        }
        // //console.log("Socket disconnected");
    };
    const handleYes = async () => {
        await fetchIdealConversation()
    };

    const handleNo = () => {
        onClose();
    };

    return (
        <Popover>
            <Transition
                show={true}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
            >
                <Popover.Panel static className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 z-50">
                    <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-lg max-w-md w-full">
                        <h2 className="text-lg font-semibold mb-4 text-neutral-900 dark:text-neutral-100">End Discussion Offer</h2>
                        <p className="text-neutral-700 dark:text-neutral-300 mb-6">
                            Your opponent has offered to end the discussion. Would you like to accept?
                        </p>
                        <div className='flex flex-row space-x-4'>
                            <button
                                type="button"
                                onClick={handleYes}
                                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-700 transition-transform transform hover:scale-105"
                            >
                                Yes
                            </button>
                            <button
                                type="button"
                                onClick={handleNo}
                                className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-700 transition-transform transform hover:scale-105"
                            >
                                No
                            </button>
                        </div>
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    );
};

export default RestP2P;
