import { motion, Variants } from 'framer-motion';

interface StoryStepProps {
  emoji: string;
  color: string; 
}

const cardVariants: Variants = {
  offscreen: {
    y: 300,
  },
  onscreen: {
    y: 10,
    rotate: 0, // Removed tilt
    transition: {
      type: 'spring',
      bounce: 0.4,
      duration: 0.6,
    },
  },
};

const Story: React.FC<StoryStepProps> = ({ emoji, color }) => {
  return (
    <motion.div
      className="relative flex items-center justify-center overflow-hidden mb-6" 
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: false, amount: 0.8 }} 
    >
      <motion.div
        className="relative flex flex-col items-center justify-center w-full rounded-lg shadow-lg overflow-hidden p-6 transition-transform duration-300 ease-in-out"
        style={{ backgroundColor: color }} 
        variants={cardVariants}
      >
        <div className="relative z-10 text-5xl mb-2">{emoji}</div>
      </motion.div>
    </motion.div>
  );
};

const steps: [string, string][] = [
  ['👤 Create an account', '#4BAF9D'], 
  ['📄 Pick a case', '#F9A825'], 
  ['📚 Prepare', '#FF7043'], 
  ['⚔️ Fight', '#1E88E5'], 
];

const StoryStep: React.FC = () => (
  <div className="mx-auto flex flex-col max-w-md pb-20 mt-16">
    <h2 className="text-3xl font-bold text-center mb-4 text-gray-900">Your Journey Starts Here!</h2>
    <p className="text-center text-gray-600 mb-8 text-lg">Follow these steps to get the most out of AI MootCourt.</p>
    {steps.map(([emoji, color]) => (
      <Story emoji={emoji} color={color} key={emoji} />
    ))}
  </div>
);

export default StoryStep;
