import { DEMO_POSTS } from "data/posts";
// import { PostDataType } from "data/types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import LoadingIcon from "components/Loading/LoadingIcon";
import axios from "axios";
import { apiUri } from "../../constants";

interface DocumentRendererProps {
  documentString: string;
}

const DocumentRenderer: React.FC<DocumentRendererProps> = ({ documentString }) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: documentString }}
      className="nc-SingleContent container space-y-10" 
    />
  );
};



interface BlogSingleProps {
index: string,
    id: string,
  featuredImage: string,
  titleImage: string,
    title: string,
    desc: string,
  date: string,
  contentInHtml: string,
  href: "/blog-single",
  authorDesc: string,
  authorName: string,
  category:string,
  readingTime: number,
  tags: string[],
  featuredImageCaption:string,
    postType: string
}

const BlogSingleRenderer: React.FC<BlogSingleProps> = ({
  featuredImage, 
  contentInHtml,
  title, 
  desc,
  date, 
  readingTime,
  titleImage,
  tags, 
  featuredImageCaption,
  authorName,
  category,
  authorDesc
}) => {
  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <Badge href="##" color="purple" name={category} />
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            {title}
          </h1>
          <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
            {desc}
          </span>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex flex-col items-baseline sm:flex-row sm:justify-between">
            <div className="nc-PostMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 text-sm leading-none flex-shrink-0">
              {/* <Avatar
                containerClassName="flex-shrink-0"
                sizeClass="w-8 h-8 sm:h-11 sm:w-11 "
              /> */}
              <div className="ml-3">
                <div className="flex items-center">
                  <a className="block font-semibold" href="/">
                    {authorName}
                  </a>
                </div>
                <div className="text-xs mt-[6px]">
                  <span className="text-neutral-700 dark:text-neutral-300">
                    {date}
                  </span>
                  <span className="mx-2 font-semibold">·</span>
                  <span className="text-neutral-700 dark:text-neutral-300">
                    {readingTime} min read
                  </span>
                </div>
              </div>
            </div>
            {/* <div className="mt-3 sm:mt-0 sm:ml-3">
              <SocialsList />
            </div> */}
          </div>
        </div>
      </header>
    );
  };

  const renderContent = () => {

    // console.log(typeof(contentInHtml))
 
       const processedContent = contentInHtml.toString()
    .replace('{featuredImage}', featuredImage)
         .replace('{featuredImageCaption}', featuredImageCaption);
    
    // console.log(processedContent)

    return (
     <div className="relative w-full h-[calc(100%-60px)] overflow-y-auto"> 
                    <DocumentRenderer documentString={processedContent} />
          </div>
    )
  }

  const renderTags = () => {
    return (
      <div className="max-w-screen-md mx-auto flex flex-wrap">
      {tags.map((tag, index) => (
        <span
          key={index}
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
        >
          {tag}
        </span>
      ))}
    </div>
    );
  };

  const renderAuthor = () => {
    return (
      <div className="max-w-screen-md mx-auto ">
        <div className="nc-SingleAuthor flex">
          {/* <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" /> */}
          <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
            <span className="text-xs text-neutral-400 uppercase tracking-wider">
              WRITEN BY
            </span>
            <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
              {authorName}
            </h2>
            <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
             {authorDesc}
           
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderCommentForm = () => {
    return (
      <div className="max-w-screen-md mx-auto pt-5">
        <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
          Responses (14)
        </h3>
        <form className="nc-SingleCommentForm mt-5">
          <Textarea />
          <div className="mt-2 space-x-3">
            <ButtonPrimary>Submit</ButtonPrimary>
            <ButtonSecondary>Cancel</ButtonSecondary>
          </div>
        </form>
      </div>
    );
  };

  const renderCommentLists = () => {
    return (
      <div className="max-w-screen-md mx-auto">
        <ul className="nc-SingleCommentLists space-y-5">
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
   };

  // const renderPostRelated = (post: PostDataType) => {
  //   return (
  //     <div
  //       key={post.id}
  //       className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
  //     >
  //       <Link to={post.href} />
  //       <NcImage
  //         className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
  //         src={post.featuredImage}
  //       />
  //       <div>
  //         <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
  //       </div>
  //       <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
  //         <Badge name="Categories" />
  //         <h2 className="block text-lg font-semibold text-white ">
  //           <span className="line-clamp-2">{post.title}</span>
  //         </h2>

  //         <div className="flex">
  //           <span className="block text-neutral-200 hover:text-white font-medium truncate">
  //             {post.author.displayName}
  //           </span>
  //           <span className="mx-1.5 font-medium">·</span>
  //           <span className="font-normal truncate">{post.date}</span>
  //         </div>
  //       </div>
  //       <Link to={post.href} />
  //     </div>
  //   );
  // };

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
   
      {renderHeader()}
      <NcImage
        className="w-full rounded-xl"
        containerClassName="container my-10 sm:my-12 "
        src={titleImage}
      />

      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
        {renderTags()}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {renderAuthor()}
        {/* {renderCommentForm()}
        {renderCommentLists()} */}
      </div>
      {/* <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
        <div className="container ">
          <h2 className="text-3xl font-semibold">Related posts</h2>
          <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
        
            {DEMO_POSTS.filter((_, i) => i < 4).map(renderPostRelated)}
       
          </div>
        </div>
      </div> */}
    </div>
  );
};

function BlogSingle() {
  const blogId = sessionStorage.getItem("blogId")
  const [details, setDetails] = useState<BlogSingleProps | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response = await axios.post(`${apiUri}/fetchBlog`, { id: blogId });
        setDetails(response.data.data);
      } catch (error) {
        console.error('Error fetching case details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, [blogId]);

  if (loading) {
    return <LoadingIcon text="Loading Blog"/>;
  } else if (details) {
    return <BlogSingleRenderer {...details} />;
  } else {
    return <p>Error loading blog.</p>;
  }

}

export default BlogSingle;
