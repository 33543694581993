import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faCheck } from '@fortawesome/free-solid-svg-icons'; // Import the "copy" icon

import React from 'react';
const CopyButton: React.FC<{ text: string | null, onClose: () => void }> = ({ text, onClose }) => {
    const [isCopied, setIsCopied] = useState(false);
    //console.log("THIS IS THE TEXT RECEIVED", text);

    const onCopyHandler = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
            onClose();
        }, 2000); // Hide the success message after 2.5 seconds
    };

    const copyToClipboard = async (text: string) => {
        // copy to clipboard
        try {
            await navigator.clipboard.writeText(text);
            // alert('Text copied to clipboard:');
            // //console.log(text);
        } catch (err) {
            alert('Error copying to clipboard:');
            //console.log(err);
        }
    }
    return (
        <button onClick={() => {
            copyToClipboard(text ? text : "");
            onCopyHandler();

        }}>{text}
            {!isCopied && <FontAwesomeIcon icon={faCopy} />}
            {isCopied && <FontAwesomeIcon icon={faCheck} />}

        </button>
    )
}

export default CopyButton;